import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

const CheckOut = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const [country, setCountry] = useState("US");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selected, setSelected] = useState("US");

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!country) newErrors.country = "Country/Region is required";
    if (!phone) newErrors.phone = "Phone number is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!address) newErrors.address = "Street address is required";
    if (!city) newErrors.city = "Town / City is required";

    // setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all  fields");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      alert("Order placed!");
      clearCart();
      navigate("/paysuccess");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * item.quantity;
    }, 0);
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price.replace("£", ""));
    return price * quantity; // 计算总价
  };

  return (
    <>
      <Header />
      <div className="main-containerCheck">
        <div className="check-custom-checkout-page">
          <div className="left">
            <div className="check-order-info">
              <h3>Billing details</h3>
              <form>
                <div className="firstLast">
                  <div className="form-group1 form-group-half1">
                    <input
                      type="text"
                      id="first-name"
                      name="first-name"
                      value={firstName}
                      placeholder="First name *"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    {errors.firstName && (
                      <p className="error">{errors.firstName}</p>
                    )}
                  </div>
                  <div className="form-group1 form-group-half">
                    <input
                      type="text"
                      id="last-name"
                      name="last-name"
                      value={lastName}
                      placeholder="Last name *"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    {errors.lastName && (
                      <p className="error">{errors.lastName}</p>
                    )}
                  </div>
                </div>
                <div className="form-group form-group-half">
                  <input
                    type="text"
                    id="company-name"
                    name="company-name"
                    placeholder="Company name (optional)"
                  />
                </div>

                <div className="form-group form-group-half">
                  <ReactFlagsSelect
                    selected={selected}
                    onSelect={(code) => {
                      setSelected(code);
                      setCountry(code);
                    }}
                    className="check-select"
                  />
                  {errors.country && <p className="error">{errors.country}</p>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    placeholder="House number and street name"
                  />
                  {errors.address && <p className="error">{errors.address}</p>}

                  <input
                    type="text"
                    id="address2"
                    name="address2"
                    value={address}
                    className="twoAdd"
                    required
                    placeholder="Apartment, suite, unit, etc. (optional)"
                  />
                </div>
                <div className="form-group form-group-half">
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={city}
                    placeholder="Town / City *"
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                  {errors.city && <p className="error">{errors.city}</p>}
                </div>
                <div className="form-group form-group-half">
                  <input
                    type="text"
                    id="company-name"
                    placeholder="State / County (optional)"
                    name="company-name"
                  />
                </div>

                <div className="form-group form-group-half">
                  <input
                    type="phone"
                    id="phone"
                    name="phone"
                    value={phone}
                    placeholder="Phone*"
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                  {errors.phone && <p className="error">{errors.phone}</p>}
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Email address *"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
              </form>
              <button
                className="check-checkout-button"
                onClick={handlePlaceOrder}
              >
                Pay Order
              </button>
            </div>
          </div>

          <div className="right">
            <div className="check-order-summary">
              <div className="check-order-details">
                {cartItems.map((item, index) => {
                  const product = getProductById(item.id);
                  return (
                    <div key={index} className="check-cart-item">
                      <img src={product.images[0]} alt="Product" />
                      <div className="check-item-details">
                        <h3>{product.name}</h3>
                        <span style={{ marginBottom: "-2%" }}>
                          <p className="check-pre-price">{product.preprice}</p>
                          <p>Qty:{item.quantity}</p>

                          {item.size && <p>Size:{item.size}</p>}
                          {item.color && <p>Color:{item.color}</p>}
                        </span>
                      </div>
                      <p className="cal-single">
                        £{calculateItemTotal(item.id, item.quantity).toFixed(2)}
                      </p>
                    </div>
                  );
                })}
                <div class="check_subtotal">
                  <div role="rowheader" class="_1qy6ue6b">
                    <span class="_19gi7yt0 _19gi7ytw _19gi7ytv _1fragemnu">
                      Subtotal
                    </span>
                  </div>
                  <div
                    role="cell"
                    class="_1qy6ue6c"
                    style={{ textAlign: "right" }}
                  >
                    <span
                      translate="no"
                      class="_19gi7yt0 _19gi7ytw _19gi7ytv _1fragemnu notranslate"
                    >
                      ${calculateTotal()}
                    </span>
                  </div>
                </div>
                <div class="check_subtotal">
                  <div role="rowheader" class="_1qy6ue6b">
                    <span class="_19gi7yt0 _19gi7ytw _19gi7ytv _1fragemnu">
                      Shipping
                    </span>
                  </div>
                  <div
                    role="cell"
                    class="_1qy6ue6c"
                    style={{ textAlign: "right" }}
                  >
                    <span
                      style={{ color: "rgb(0 0 0 / 56%)" }}
                      translate="no"
                      class="_19gi7yt0 _19gi7ytw _19gi7ytv _1fragemnu notranslate"
                    >
                      Enter shipping address
                    </span>
                  </div>
                </div>
                <div class="check_subtotal">
                  <div role="rowheader" class="_1qy6ue6b">
                    <span
                      class="_19gi7yt0 _19gi7ytw _19gi7ytv _1fragemnu"
                      style={{
                        color: "rgb(0,0,0)",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Total
                    </span>
                  </div>
                  <div
                    role="cell"
                    class="_1qy6ue6c"
                    style={{ textAlign: "right" }}
                  >
                    <span
                      style={{ color: "rgb(0 0 0 / 56%)" }}
                      translate="no"
                      class="_19gi7yt0 _19gi7ytw _19gi7ytv _1fragemnu notranslate"
                    >
                      USD&nbsp;
                      <span
                        class="_19gi7yt0 _19gi7ytw _19gi7ytv _1fragemnu"
                        style={{
                          color: "rgb(0,0,0)",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        ${calculateTotal()}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
