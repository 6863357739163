import "../css/header.css";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const { cartItems } = useContext(CartContext);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const toBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="header-container">
        <div className="header_top">
          <div className="header_top_inner">
            48 hours only: Get an EXTRA 10% OFF** in the app
          </div>
        </div>

        <div className="header_mid">
          <div className="header_mid_inner">
            <div className="header_mid_left">
              <Link to="/" onClick={toTop}>
                <svg
                  viewBox="0 0 629.52 150"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  height="100%"
                  width="100%"
                  class="_6o3atz13e _6o3atz1bd _6o3atz16r _6o3atzdf"
                >
                  <title>Wayfair</title>
                  <path
                    fill="#7b189f"
                    stroke-width="0px"
                    d="M624.09,42.29c0-0.77-0.42-1.54-1.47-1.54h-1.89v4.96h0.7V43.9h0.63l1.26,1.81h0.84l-1.33-1.89 C623.74,43.76,624.09,42.99,624.09,42.29L624.09,42.29z M621.44,43.2v-1.81h1.26c0.56,0,0.77,0.49,0.77,0.91 c0,0.42-0.21,0.91-0.84,0.91L621.44,43.2L621.44,43.2z"
                  ></path>
                  <path
                    fill="#7b189f"
                    stroke-width="0px"
                    d="M622.27,38.94c-2.38,0-4.26,1.89-4.26,4.26c0,2.38,1.89,4.26,4.26,4.26s4.26-1.89,4.26-4.26 C626.54,40.82,624.58,38.94,622.27,38.94z M622.27,46.83c-2.03,0-3.63-1.61-3.63-3.63c-0.07-2.03,1.61-3.63,3.63-3.63 s3.63,1.61,3.63,3.63C625.91,45.23,624.3,46.83,622.27,46.83z"
                  ></path>
                  <ellipse
                    fill="#7b189f"
                    stroke-width="0px"
                    cx="541.78"
                    cy="15.8"
                    rx="11.49"
                    ry="11.2"
                  ></ellipse>
                  <rect
                    x="-42.72"
                    y="-20.74"
                    fill="none"
                    stroke-width="0px"
                    width="945.79"
                    height="191.47"
                  ></rect>
                  <path
                    fill="#7b189f"
                    stroke-width="0px"
                    d="M53.15,111.51L19.41,77.76L7.77,89.4c-1.17,1.17-1.31,2.04-0.87,3.49l5.53,21.53 c0.58,2.33,1.74,3.49,4.07,4.07l21.53,5.53c1.45,0.44,2.33,0.29,3.49-0.87L53.15,111.51L53.15,111.51z M58.68,111.51l11.64,11.64 c1.17,1.17,2.04,1.31,3.49,0.87l21.53-5.53c2.33-0.58,3.49-1.74,4.07-4.07l5.53-21.53c0.44-1.45,0.29-2.33-0.87-3.49L92.43,77.76 L58.68,111.51L58.68,111.51z M58.68,38.49l33.75,33.75l11.64-11.64c1.17-1.17,1.31-2.04,0.87-3.49l-5.53-21.53 c-0.58-2.33-1.74-3.49-4.07-4.07l-21.53-5.53c-1.45-0.44-2.33-0.29-3.49,0.87L58.68,38.49L58.68,38.49z M53.15,38.49L41.52,26.85 c-1.17-1.17-2.04-1.31-3.49-0.87L16.5,31.51c-2.33,0.58-3.49,1.74-4.07,4.07L6.9,57.1c-0.44,1.45-0.29,2.33,0.87,3.49l11.64,11.64 L53.15,38.49L53.15,38.49z"
                  ></path>
                  <path
                    fill="#7b189f"
                    stroke-width="0px"
                    d="M437.35,53.47h-17.9v52.64c0,2.74-2.22,4.96-4.96,4.96h-13.95v-57.6h-16.28l-21.6,61.24 c-8.73,24.58-17.31,30.98-32.58,30.98c-2.91,0-7.56-0.58-12.22-1.45l4.12-13.53h6.65c7.34,0,11.21-2.28,13.75-8.68 c2.53-6.41,2.59-7.4,2.62-7.46L319.1,38.93h16.41c2.18,0,4.1,1.42,4.74,3.5l14.63,47.55L370.3,43c1.02-2.91,2.33-4.07,5.24-4.07 h25.01v-2.04c0-21.38,9.89-32.58,28.66-32.58c2.91,0,9.49,0.52,13.42,1.4l-4.15,13.58h-5.49c-7.47,0-13.53,6.06-13.53,13.53v6.11 h22.31L437.35,53.47L437.35,53.47z"
                  ></path>
                  <path
                    fill="#7b189f"
                    stroke-width="0px"
                    d="M200.36,89.4l-14.54-47.27c-0.73-2.47-1.74-3.2-4.22-3.2h-13.82c-2.47,0-3.49,0.73-4.22,3.2L148.87,89.4 l-13.09-50.47h-20.8l20.95,68.95c0.73,2.47,1.74,3.2,4.22,3.2h14.4c2.47,0,3.49-0.73,4.22-3.2l15.27-48.58l15.42,48.58 c0.73,2.47,1.74,3.2,4.22,3.2h12.95c2.47,0,3.49-0.73,4.22-3.2l20.95-68.95H213.6L200.36,89.4L200.36,89.4z"
                  ></path>
                  <rect
                    x="532.32"
                    y="38.93"
                    fill="#7b189f"
                    stroke-width="0px"
                    width="18.91"
                    height="72.15"
                  ></rect>
                  <path
                    fill="#7b189f"
                    stroke-width="0px"
                    d="M291.9,43l-0.44,8.3c-4.51-10.77-13.67-13.82-24.44-13.82c-19.78,0-32.15,17.89-32.15,37.53 s12.36,37.53,32.15,37.53c10.77,0,19.93-3.06,24.44-13.82l0.44,8.3c0,2.76,1.31,4.07,3.64,4.07h15.13V38.93h-15.13 C293.21,38.93,291.9,40.23,291.9,43L291.9,43z M272.39,97.44c-11.93,0-18.75-9.94-18.75-22.44s6.81-22.44,18.75-22.44 S291.14,62.5,291.14,75S284.32,97.44,272.39,97.44L272.39,97.44z"
                  ></path>
                  <path
                    fill="#7b189f"
                    stroke-width="0px"
                    d="M497.64,43l-0.44,8.3c-4.51-10.77-13.67-13.82-24.44-13.82c-19.78,0-32.15,17.89-32.15,37.53 s12.36,37.53,32.15,37.53c10.77,0,19.93-3.06,24.44-13.82l0.44,8.3c0,2.76,1.31,4.07,3.64,4.07h15.13V38.93h-15.13 C498.96,38.93,497.64,40.23,497.64,43L497.64,43z M478.14,97.44c-11.93,0-18.75-9.94-18.75-22.44s6.81-22.44,18.75-22.44 S496.89,62.5,496.89,75S490.07,97.44,478.14,97.44z"
                  ></path>
                  <path
                    fill="#7b189f"
                    stroke-width="0px"
                    d="M608.98,38.2c-11.2,0-19.2,3.44-22.69,16.82L586,43c0-2.76-1.31-4.07-3.64-4.07h-15.13v72.15h18.91V75 c0-12.65,7.27-20.8,19.78-20.8h3.41l4.59-15.09C612.16,38.36,610.29,38.2,608.98,38.2L608.98,38.2z"
                  ></path>
                </svg>
              </Link>
            </div>

            <div className="header_mid_right">
              <Link to="/cartpage" onClick={toTop}>
                <div className="header_right_cart_page">
                  <div class="zwpau96">
                    <sup
                      class="ssk87c1"
                      data-enzyme-id="CartContentBadgeQuantity"
                    >
                      {cartItems.length}
                    </sup>
                    <svg
                      focusable="false"
                      viewBox="2 2 24 24"
                      __typename="HomebaseIcon"
                      logger="[object Object]"
                      aria-hidden="true"
                      data-hb-id="pl-icon"
                      data-enzyme-id="HomebaseIcon"
                      class="_1fr089m0 _1fr089m1 zwpau97"
                    >
                      <path d="M21 15.54a.51.51 0 00.49-.38l2-8a.51.51 0 00-.1-.43.49.49 0 00-.39-.19H8.28L8 4.9a.51.51 0 00-.49-.4H5a.5.5 0 000 1h2.05L9 15l-2.36 4.74a.53.53 0 000 .49.5.5 0 00.42.23H21a.5.5 0 00.5-.5.5.5 0 00-.5-.5H7.89l1.92-3.92zm1.34-8l-1.73 7H9.92l-1.43-7zM10 21a1 1 0 101 1 1 1 0 00-1-1zM18 21a1 1 0 101 1 1 1 0 00-1-1z"></path>
                    </svg>
                  </div>
                  <span class="zwpau98 zwpau9b">Cart</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="header_bot">
        <ul>
          <li>
            <Link to="/" onClick={toTop}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/" onClick={toBottom}>
              Contact Us
            </Link>
          </li>
          <li>
            <Link to="/privacy" onClick={toTop}>
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Header;
