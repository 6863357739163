import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/about.css";

function About() {
  return (
    <div className="main-container">
      <Header />

      <div className="about_box">
        <div className="about1_box">
          <div className="about1_box_inner">
            <div className="about1_body">
              <span>
                We’re here to help everyone, anywhere create their feeling of
                home.
              </span>
            </div>
          </div>
        </div>

        <div className="about2_box">
          <div className="about2_box_inner">
            <h2>What’s New</h2>
            <div className="about2_inner_one">
              <div className="about2_inner_one_left">
                <img
                  class="Image"
                  data-image-size="leadPromo"
                  alt="Wayfair Willmette Store32079_final.jpg"
                  srcset="https://cdn.aboutwayfair.com/dims4/default/e12c7fb/2147483647/strip/true/crop/6655x4991+416+0/resize/1120x840!/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2Fae%2Fb1%2F9a0b44e240d7880054d98a489909%2Fwayfair-willmette-store32079-final.jpg 2x"
                  width="560"
                  height="420"
                  src="https://cdn.aboutwayfair.com/dims4/default/594dfba/2147483647/strip/true/crop/6655x4991+416+0/resize/560x420!/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2Fae%2Fb1%2F9a0b44e240d7880054d98a489909%2Fwayfair-willmette-store32079-final.jpg"
                ></img>
              </div>
              <div className="about2_inner_one_right">
                <div class="PromoLeadF-categories">Company News</div>
                <h1>
                  Now Open: Wayfair's First Large-Format Store Reimagines the
                  Shopping Experience for Home
                </h1>
              </div>
            </div>
            <div className="about2_inner_two">
              <div className="about2_inner_two_body">
                <div className="about2_inner_two_body_img">
                  <img
                    alt=""
                    src="https://cdn.aboutwayfair.com/dims4/default/7c51509/2147483647/strip/true/crop/1820x1365+114+0/resize/1600x1200!/format/webp/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2Ffa%2Fbb%2Fd98a27a3404989e0ae6683299b9f%2F21-web-or-mls-g57a1018.jpg"
                  />
                </div>
                <div class="PromoCardA-categories">
                  Community Engagement , DEI
                </div>
                <div className="PromoCardA-title">
                  Marine LCPL Alberto Flores Jr. Receives a Life-Changing
                  Specially Adapted Custom Home
                </div>
              </div>{" "}
              <div className="about2_inner_two_body">
                <div className="about2_inner_two_body_img">
                  <img
                    alt=""
                    src="https://cdn.aboutwayfair.com/dims4/default/1bdef63/2147483647/strip/true/crop/1741x1306+97+0/resize/1600x1200!/format/webp/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2F01%2F33%2Fefefb2814ab5bd779ddb55b45a5d%2Fartboard-25-2x.png"
                  />
                </div>
                <div class="PromoCardA-categories">
                  Sustainability, Lifestyle
                </div>
                <div className="PromoCardA-title">
                  Company C: 30 Years of Colorful, Sustainable Rugs
                </div>
              </div>{" "}
              <div className="about2_inner_two_body">
                <div className="about2_inner_two_body_img">
                  <img
                    alt=""
                    src="https://cdn.aboutwayfair.com/dims4/default/82e8ae1/2147483647/strip/true/crop/1440x1080+240+0/resize/1600x1200!/format/webp/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2F3d%2Feb%2F1a465c6f492aa9c3de41dc83fad2%2Fwayfair-community-solutions.png"
                  />
                </div>
                <div class="PromoCardA-categories">Community Engagement</div>
                <div className="PromoCardA-title">
                  Recognizing World Homelessness Day with Community Solutions
                </div>
              </div>
            </div>
            <div className="about2_inner_three">
              <div className="about2_inner_three_left">
                <h2>A Global Brand</h2>
                <div class="ListL-header-description">
                  With global headquarters and an extensive network of logistics
                  hubs and customer service centers, we’re here to create that
                  feeling of home for everyone, anywhere.
                  <br />
                </div>
                <div class="PromoTextOnly-title">
                  <span class="Link ">Corporate Offices: Purple Pins</span>
                </div>
                <div class="PromoTextOnly-description">
                  Our corporate headquarters in Boston and Berlin are surrounded
                  by world-class technology and educational institutions,
                  providing access to top talent.
                </div>

                <div class="PromoTextOnly-title">
                  <span class="Link ">
                    Fulfillment & Home Delivery Network: Yellow Pins
                  </span>
                </div>
                <div class="PromoTextOnly-description">
                  We operate 18 fulfillment and 38 delivery centers representing
                  millions of square feet across the U.S., Germany, Canada, and
                  the U.K.
                </div>

                <div class="PromoTextOnly-title">
                  <span class="Link ">Sales & Service Centers: Green Pins</span>
                </div>
                <div class="PromoTextOnly-description">
                  Our Sales & Service teams in the U.S., Germany, Ireland,
                  Canada, and the U.K. along with our virtual team allow us to
                  meet the needs of our global customer base.
                </div>
              </div>

              <div className="about2_inner_three_right">
                <img
                  alt=""
                  src="https://cdn.aboutwayfair.com/dims4/default/ff578b5/2147483647/strip/true/crop/1387x981+7+0/resize/1640x1160!/format/webp/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2F85%2F37%2Fdebf78a141bea195023d6f14ab07%2Fartboard-3-100.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="about3_box">
          <div class="ModuleHeader">
            <h2 class="ModuleHeader-title">Wayfair Fast Facts</h2>
          </div>

          <div className="about3_box_grid">
            <div className="about3_box_grid_body">
              <div class="PromoFastFacts-category">
                <span class="Link ">One of the world's</span>
              </div>
              <div class="PromoFastFacts-title">
                <span class="Link ">largest</span>
              </div>
              <div class="PromoFastFacts-description">home retailers.</div>
            </div>
            <div className="about3_box_grid_body">
              <div class="PromoFastFacts-title">
                <span class="Link ">$12.0 Billion</span>
              </div>
              <div class="PromoFastFacts-description">
                in net revenue for the twelve months ended December 31, 2023.
              </div>
            </div>
            <div className="about3_box_grid_body">
              <div class="PromoFastFacts-category">
                <span class="Link ">Serving</span>
              </div>
              <div class="PromoFastFacts-title">
                <span class="Link ">21.7 Million</span>
              </div>
              <div class="PromoFastFacts-description">
                active customers and counting.
              </div>
            </div>
            <div className="about3_box_grid_body">
              <div class="PromoFastFacts-title">
                <span class="Link ">Founder-led</span>
              </div>
              <div class="PromoFastFacts-description">
                since inception in 2002.
              </div>
            </div>
            <div className="about3_box_grid_body">
              <div class="PromoFastFacts-category">
                <span class="Link ">Home to</span>
              </div>
              <div class="PromoFastFacts-title">
                <span class="Link ">&gt;30 Million</span>
              </div>
              <div class="PromoFastFacts-description">
                products for any home need from 20K+ suppliers.
              </div>
            </div>
            <div className="about3_box_grid_body">
              <div class="PromoFastFacts-category">
                <span class="Link ">More than</span>
              </div>
              <div class="PromoFastFacts-title">
                <span class="Link ">11K</span>
              </div>
              <div class="PromoFastFacts-description">
                employees with operations in North America and Europe.
              </div>
            </div>
          </div>
        </div>

        <div className="about4_box">
          <div class="ModuleHeader">
            <h2 class="ModuleHeader-title">Explore More About Us</h2>
          </div>
          <div className="about4_gird">
            <div className="about4_gird_body">
              <div className="PromoI-media">
                <img
                  alt=""
                  src="https://cdn.aboutwayfair.com/dims4/default/d32fe2d/2147483647/strip/true/crop/1680x1260+0+0/resize/1600x1200!/format/webp/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2Ff4%2Fd6%2F646b35194eb4b0eb4e93a3ff70fb%2Faboutus-whoweare-3720x1320.jpg"
                />
              </div>
              <div class="PromoI-title">Who We Are</div>
            </div>
            <div className="about4_gird_body">
              <div className="PromoI-media">
                <img
                  alt=""
                  src="https://cdn.aboutwayfair.com/dims4/default/6c54929/2147483647/strip/true/crop/1680x1260+0+0/resize/1600x1200!/format/webp/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2F94%2F44%2F9637cd884cf39434882fbf14a532%2Faboutus-ourimpact-1680x1260.jpg"
                />
              </div>
              <div class="PromoI-title">Our Impact</div>
            </div>
            <div className="about4_gird_body">
              <div className="PromoI-media">
                <img
                  alt=""
                  src="https://cdn.aboutwayfair.com/dims4/default/0a7d6aa/2147483647/strip/true/crop/1680x1260+0+0/resize/1600x1200!/format/webp/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2Fa4%2F9e%2F37946693444ab932f6c107340f03%2Faboutus-careers-1680x1260.jpg"
                />
              </div>
              <div class="PromoI-title">Careers</div>
            </div>
            <div className="about4_gird_body">
              <div className="PromoI-media">
                <img
                  alt=""
                  src="https://cdn.aboutwayfair.com/dims4/default/60c149a/2147483647/strip/true/crop/1680x1260+0+0/resize/1600x1200!/format/webp/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2Fc5%2Fce%2F6921506746f3baf2e2712f1a030f%2Faboutus-investorrelations-1680x1260.jpg"
                />
              </div>
              <div class="PromoI-title">Investor Relations</div>
            </div>
            <div className="about4_gird_body">
              <div className="PromoI-media">
                <img
                  alt=""
                  src="https://cdn.aboutwayfair.com/dims4/default/c0b0aaf/2147483647/strip/true/crop/1680x1260+0+0/resize/1600x1200!/format/webp/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2F8e%2Ff9%2F217c553342e6a169ed0e6c8c8ac6%2Faboutus-news-stories-1680x1260.jpg"
                />
              </div>
              <div class="PromoI-title">News & Stories</div>
            </div>
            <div className="about4_gird_body">
              <div className="PromoI-media">
                <img
                  alt=""
                  src="https://cdn.aboutwayfair.com/dims4/default/3506e1d/2147483647/strip/true/crop/1680x1260+0+0/resize/1600x1200!/format/webp/quality/90/?url=https%3A%2F%2Fcdn.aboutwayfair.com%2Fa9%2Fd3%2F7895bc014f39bfe2a431f2635ae0%2Faboutus-partnerwithus-1680x1260.jpg"
                />
              </div>
              <div class="PromoI-title">Partner With Us</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
