const productData = [
  {
    id: 1,
    name: "Amber Lewis x Loloi Monty Ivory / Beige Area Rug",
    reviews: "29",
    prePrice: "66.99",
    price: "48.99",
    sale: "25%",
    size: [
      "Rectangle 4' x 6'",
      "Rectangle 5'3\" x 7'6\"",
      "Rectangle 6'7\" x 9'3\"",
      "Rectangle 7'10\" x 10'",
      "Rectangle 9'2\" x 13'",
      "Rectangle 11'6\" x 15'",
      "Runner 2'7\"  x  4'",
      "Runner 2'7\"  x  8'",
      "Runner 2'7\"  x  10'",
      "Square 1'6\"",
    ],
    color: [],
    description: {
      main: [
        "The Monty Collection by Amber Lewis x Loloi is a modern interpretation of richly piled Moroccan rugs in a refreshingly neutral color palette. The rug’s abstract designs create a high-low texture in the pile for feet to sink into. This collection is power-loomed of easy-to-clean polyester in Turkey for high-traffic durability and is OEKO-TEX certified, ensuring its materials don’t contain harmful substances.",
      ],
      inclued: [],
      features: [
        "Modern Area Rug for Living Room and More: The Monty Collection is a neutral and graphic area rug with an inviting high-low texture; beautifully designed by Amber Lewis x Loloi",
        "Tonal Neutrals: The color of the rug is influenced by your environment, lighting, and time of day; rugs may contain temporary creases upon arrival, allow time for creases to flatten and settle",
        "Easy to Clean: The accent rug is power-loomed of polyester in Turkey for easy-to-clean, no-shed durability and OEKO-TEX certified, ensuring its materials don't contain harmful substances",
        "Great for High-Traffic Areas: The Amber Lewis Monty rug is a refreshing neutral design that can serve as a living room rug, bedroom rug, entryway rug, or more; a rug pad is recommended for extra cushion and to keep the rug firmly in place",
        "Designer Collaboration: Amber Lewis x Loloi is a textile collaboration with a laid-back yet luxurious aesthetic at all price points",
      ],
      purposeful: [],
    },
    specification: {
      SaladPlate: "",
      DinnerPlate: "",
      Mug: "",
      IndividualCupMugWeight: "",
      DinnerPlateWeight: "",
      IndividualStorageCompartment: "",
      ServiceFor: "",
      PlateTypesIncluded: "",
      DinnerPlateIncluded: "",
      NumberofDinnerPlatesIncluded: "",
      SaladPlateIncluded: "",
      NumberofSaladPlatesIncluded: "",
      MainPlateShape: "",
      CerealBowlIncluded: "",
      NumberofCerealBowlsIncluded: "",
      AdditionalTablewareIncluded: "",
      AdditionalTypesofTablewareIncluded: "",
      ConstructionMethod: "",
      MugIncluded: "",
      NumberofMugsIncluded: "",
      RimDetail: "",
      FinishTreatment: "",
      BowlType: "",
      Overall: "",
      Seat: "",
      Leg: "",
      OverallProductWeight: "",
      MinimumOverallHeightToptoBottom: "",
      BaseFinish: "",
      Distancebetweeneachhook: "",
      FrameMaterial: "Plastic",
      SeatMaterial: "Fabric",
      UpholsteryMaterial: "Polyester Blend",
      WeightCapacity: "225 lb.",
      StorageBenchIncluded: "",
      ShelvesIncluded: "",
      HooksIncluded: "",
      NumberofHooksIncluded: "",
      ShoeStorageIncluded: "",
      IndividualCompartmentsIncluded: "",
      MirrorIncluded: "",
      TipoverRestraintDeviceIncluded: "",
      MountType: "",
      BenchMaterial: "",
      BenchWeightCapacity: "",
      WeightCapacityPerHook: "",
      NumberofShelves: "",
      ProductType: "",
      ArcadeGameandSlotMachineFeatures: "",
      NumberofGamesIncluded: "",
      NumberofPlayers: "",
      PowerSource: "",
      PlugIn: "",
      AssemblyRequired: "",
      AdditionalToolsRequired: "",
      AvoidPowerTools: "",
      AssemblyInstallationStepsRequired: "",
      Material: "Polyester",
      BathroomCabinetIncluded: "",
      DoorsIncluded: "",
      Imported: "",
      Durability: "",
      HandleMaterial: "",
      DoorMaterial: "",
      SoftClosing: "",
      InstallationRequired: "",
      MountingInstallationType: "",
      ColorFinish: "",
      TotalNumberofPiecesIncluded: "",
      PiecesIncluded: "",
      SaucepanIncluded: "",
      NumberofSaucepansIncluded: "",
      FryingPanSkilletIncluded: "",
      NumberofFryingPansSkilletsIncluded: "",
      SautePanIncluded: "",
      NumberofSautePansIncluded: "",
      DutchOvenIncluded: "",
      NumberofDutchOvensIncluded: "",
      LidIncluded: "",
      NumberofLidsIncluded: "",
      LidMaterial: "",
      NonStick: "",
      NonStickLayers: "",
      Plys: "",
      OvenSafe: "",
      MaximumTemperature: "",
      ProductCare: "",
      ScratchResistant: "",
      Stackable: "",
      TechniqueWeave: "Power Loom",
      Pattern: "Abstract",
      PurposefulDistressingType: "Yes",
      Location: "Indoor",
      NonSlipBacking: "No",
      Reversible: "No",
      BackingMaterial: "Jute",
      Designer: "Amber Lewis",
      Color: "Ivory/Beige",
      ProductWarranty: "",
      WarrantyLength: "",
      FullorLimitedWarranty: "",
      WarrantyDetails: "",
      SOR2016176Compliant: "Yes",
      CommercialWarranty: "No",
    },

    images: [
      "https://assets.wfcdn.com/im/30472575/resize-h800-w800%5Ecompr-r85/3004/300449240/Amber+Lewis+x+Loloi+Monty++Ivory+%2F+Beige+Area+Rug%2C+Rectangle+5%273%22+x+7%276%22.jpg",
      "https://assets.wfcdn.com/im/16483404/resize-h800-w800%5Ecompr-r85/3004/300445937/Amber+Lewis+x+Loloi+Monty++Ivory+%2F+Beige+Area+Rug%2C+Rectangle+5%273%22+x+7%276%22.jpg",
      "https://assets.wfcdn.com/im/88267692/resize-h800-w800%5Ecompr-r85/3004/300453673/Amber+Lewis+x+Loloi+Monty++Ivory+%2F+Beige+Area+Rug%2C+Rectangle+5%273%22+x+7%276%22.jpg",
      "https://assets.wfcdn.com/im/25207469/resize-h800-w800%5Ecompr-r85/3004/300453603/Amber+Lewis+x+Loloi+Monty++Ivory+%2F+Beige+Area+Rug%2C+Rectangle+5%273%22+x+7%276%22.jpg",
      "https://assets.wfcdn.com/im/96871972/resize-h800-w800%5Ecompr-r85/3004/300449133/Amber+Lewis+x+Loloi+Monty++Ivory+%2F+Beige+Area+Rug%2C+Rectangle+5%273%22+x+7%276%22.jpg",
    ],
  },
  {
    id: 2,
    name: "412 Classic Retro Games Cocktail Arcade Machine for Home or Work - Full Size - 2-Player",
    reviews: "115",
    prePrice: "1199.99",
    price: "899.00",
    sale: "25%",
    size: [
      "Rectangle 4' x 6'",
      "Rectangle 5'3\" x 7'6\"",
      "Rectangle 6'7\" x 9'3\"",
      "Rectangle 7'10\" x 10'",
      "Rectangle 9'2\" x 13'",
      "Rectangle 11'6\" x 15'",
      "Runner 2'7\"  x  4'",
      "Runner 2'7\"  x  8'",
      "Runner 2'7\"  x  10'",
      "Square 1'6\"",
    ],
    color: [],
    description: {
      main: [
        "People of all ages love the classic arcade games from the 1980s. This machine has all your favorites. All machines are free to play. This is a brand-new custom arcade with retro artwork reminiscent of a bygone era. It comes with a 5-year warranty and free support for the life of your machine.",
        [
          "-412 Classic Arcade Games",
          "-5 Year Full Warranty",
          '-Full Size 19" LCD Screen',
          '-Size: 27" Tall x 22" Wide x 37" Deep',
          "-Weight: 60 Pounds",
        ],
        "This machine has 412 1980’s classic games.",
        "Email or visit the site for a full list.",
        [
          "-State of The Art Electronics",
          "-Authentic Sound, Graphics & Action",
          "-Full Size 19″ LCD Screen",
          "-Full-Size Arcade Buttons",
          "-Professional Long Lasting Joystick",
        ],
      ],
      inclued: [],
      features: [
        "Revisit the classics: Revisit your childhood with these 412 classic arcade games from the 80s - play with friends or by yourself and try to beat your high score - add this to your game room, man cave, or theater room for a family fun option for all ages",
        "Design: Professional craftsmanship is the name of the game - we take arcades seriously and build each unit to order right here in the USA - we paint and assemble by hand and use the best materials including a scratch-resistant plexiglass top that won't break or crack like traditional glass",
        'Full size: Full-size buttons and joystick along with a 19" LCD - exactly as it was in the arcade days of old - it has a set of buttons and joysticks on both sides for competitive play too',
        "Home, bar, work, or anywhere: Perfect fit for anywhere in your home whether it be a bedroom, game room, theater room, or familiy room - enjoy the retro gameplay anywhere - maybe even buy this for your office break room.",
        "Made in the USA and Warranty: A 5-year warranty comes with the machine.",
      ],
      purposeful: [],
    },
    specification: {
      SaladPlate: "",
      DinnerPlate: "",
      Mug: "",
      IndividualCupMugWeight: "",
      DinnerPlateWeight: "",
      IndividualStorageCompartment: "",
      ServiceFor: "",
      PlateTypesIncluded: "",
      DinnerPlateIncluded: "",
      NumberofDinnerPlatesIncluded: "",

      SaladPlateIncluded: "",
      NumberofSaladPlatesIncluded: "",
      MainPlateShape: "",
      CerealBowlIncluded: "",
      NumberofCerealBowlsIncluded: "",
      AdditionalTablewareIncluded: "",
      AdditionalTypesofTablewareIncluded: "",
      ConstructionMethod: "",
      MugIncluded: "",
      NumberofMugsIncluded: "",
      RimDetail: "",
      FinishTreatment: "",
      BowlType: "",
      Overall: "",
      Seat: "",
      Leg: "",
      OverallProductWeight: "",
      MinimumOverallHeightToptoBottom: "",
      BaseFinish: "",
      Distancebetweeneachhook: "",
      FrameMaterial: "",
      SeatMaterial: "",
      UpholsteryMaterial: "",
      WeightCapacity: "",
      StorageBenchIncluded: "",
      ShelvesIncluded: "",
      HooksIncluded: "",
      NumberofHooksIncluded: "",
      ShoeStorageIncluded: "",
      IndividualCompartmentsIncluded: "",
      MirrorIncluded: "",
      TipoverRestraintDeviceIncluded: "",
      MountType: "",
      PurposefulDistressingType: "",
      BenchMaterial: "",
      BenchWeightCapacity: "",
      WeightCapacityPerHook: "",
      NumberofShelves: "",
      ProductType: "Cocktail Arcade Machine",
      ArcadeGameandSlotMachineFeatures: "LCD Screen",
      NumberofGamesIncluded: "412",
      NumberofPlayers: "2",
      PowerSource: "Plug-in",
      PlugIn: "Yes",
      AssemblyRequired: "Yes",
      AdditionalToolsRequired: "",
      AvoidPowerTools: "",
      AssemblyInstallationStepsRequired: "Basic Assembly",
      Material: "",
      BathroomCabinetIncluded: "",
      DoorsIncluded: "",
      Imported: "",
      Durability: "",
      HandleMaterial: "",
      DoorMaterial: "",
      SoftClosing: "",
      InstallationRequired: "",
      MountingInstallationType: "",
      ColorFinish: "",
      TotalNumberofPiecesIncluded: "",
      PiecesIncluded: "",
      SaucepanIncluded: "",
      NumberofSaucepansIncluded: "",
      FryingPanSkilletIncluded: "",
      NumberofFryingPansSkilletsIncluded: "",
      SautePanIncluded: "",
      NumberofSautePansIncluded: "",
      DutchOvenIncluded: "",
      NumberofDutchOvensIncluded: "",
      LidIncluded: "",
      NumberofLidsIncluded: "",
      LidMaterial: "",
      NonStick: "",
      NonStickLayers: "",
      Plys: "",
      OvenSafe: "",
      MaximumTemperature: "",
      ProductCare: "",
      ScratchResistant: "",
      Stackable: "",
      TechniqueWeave: "",
      Pattern: "",
      Location: "",
      NonSlipBacking: "",
      Reversible: "",
      BackingMaterial: "",
      Designer: "",
      Color: "",
      CommercialWarranty: "No",
      ProductWarranty: "Yes",
      WarrantyLength: "5 Years",
      FullorLimitedWarranty: "Limited",
      WarrantyDetails: "Consumer misuse not covered",
      SOR2016176Compliant: "",
    },
    images: [
      "https://assets.wfcdn.com/im/26692222/resize-h800-w800%5Ecompr-r85/1925/192578313/412+Classic+Retro+Games+Cocktail+Arcade+Machine+for+Home+or+Work+-+Full+Size+-+2-Player.jpg",
      "https://assets.wfcdn.com/im/43943722/resize-h800-w800%5Ecompr-r85/2769/276984423/412+Classic+Retro+Games+Cocktail+Arcade+Machine+for+Home+or+Work+-+Full+Size+-+2-Player.jpg",
    ],
  },
  {
    id: 3,
    name: "Henckels Clad H3 10-Pc Stainless Steel Cookware Set",
    reviews: "11",
    prePrice: "430.00",
    price: "249.95",
    sale: "42%",
    size: [],
    color: [],
    description: {
      main: [
        "High-performance cookware designed for effortless cooking.HENCKELS Clad H3 stainless-steel cookware offers exceptional performance combined with durability, technological innovation, and exquisite style. Sear meat and sauté vegetables in the 10-inch fry pan, steam rice and make oatmeal in the 2-quart saucepan and braise one-pot dinners for the family in the 6-quart Dutch oven. The heavy-duty, tri-ply clad construction with an aluminum core heats up evenly and quickly. With a highly polished mirror finish, Clad H3 adds style and sophistication to any kitchen.The tempered glass lids lock in moisture and let you monitor the cooking process while the flared pouring rim offers seamless, mess-free pouring. Thanks to the stay-cool, hollow cast handles with hanging loop, you can easily store your cookware. Clad H3 is compatible with all stovetops, including induction, and is oven safe to 500°F. Dishwasher safe, HENCKELS Clad H3 is also simple to clean, making this easy-to-use cookware a pleasure to cook with.",
      ],
      inclued: [
        "1 Dutch Oven",
        "4 Lid",
        "2 Saucepan",
        "2 Frying Pan / Skillet",
        "1 Saute Pan",
      ],
      features: [
        "Heavy-duty, tri-ply clad construction with aluminum core heats up evenly and quickly",
        "Polished, mirror finish",
        "Pouring spout and flared rim provide clean and mess-free pouring",
        "Clearly marked capacity filling scale on pot interior",
        "Glass lid with handy built-in strainer",
        "Comfortable stay-cool, hollow cast handles offer maximum maneuverability",
        "Compatible with all cooking surfaces, including induction; Oven safe to 500°F, 400°F with glass lid",
      ],
      purposeful: [],
    },
    specification: {
      SaladPlate: "",
      DinnerPlate: "",
      Mug: "",
      IndividualCupMugWeight: "",
      DinnerPlateWeight: "",
      IndividualStorageCompartment: "",
      ServiceFor: "",
      PlateTypesIncluded: "",
      DinnerPlateIncluded: "",
      NumberofDinnerPlatesIncluded: "",

      SaladPlateIncluded: "",
      NumberofSaladPlatesIncluded: "",
      MainPlateShape: "",
      CerealBowlIncluded: "",
      NumberofCerealBowlsIncluded: "",
      AdditionalTablewareIncluded: "",
      AdditionalTypesofTablewareIncluded: "",
      ConstructionMethod: "",
      MugIncluded: "",
      NumberofMugsIncluded: "",
      RimDetail: "",
      FinishTreatment: "",
      BowlType: "",
      Overall: "",
      Seat: "",
      Leg: "",
      OverallProductWeight: "",
      MinimumOverallHeightToptoBottom: "",
      BaseFinish: "",
      Distancebetweeneachhook: "",
      FrameMaterial: "",
      SeatMaterial: "",
      UpholsteryMaterial: "",
      WeightCapacity: "",
      StorageBenchIncluded: "",
      ShelvesIncluded: "",
      HooksIncluded: "",
      NumberofHooksIncluded: "",
      ShoeStorageIncluded: "",
      IndividualCompartmentsIncluded: "",
      MirrorIncluded: "",
      TipoverRestraintDeviceIncluded: "",
      MountType: "",
      PurposefulDistressingType: "",
      BenchMaterial: "",
      BenchWeightCapacity: "",
      WeightCapacityPerHook: "",
      NumberofShelves: "",
      ProductType: "",
      ArcadeGameandSlotMachineFeatures: "",
      NumberofGamesIncluded: "",
      NumberofPlayers: "",
      PowerSource: "",
      PlugIn: "",
      AssemblyRequired: "",
      AdditionalToolsRequired: "",
      AvoidPowerTools: "",
      AssemblyInstallationStepsRequired: "",
      Material: "Stainless Steel (18/10)",
      BathroomCabinetIncluded: "",
      DoorsIncluded: "",
      Imported: "",
      Durability: "",
      HandleMaterial: "",
      DoorMaterial: "",
      SoftClosing: "",
      InstallationRequired: "",
      MountingInstallationType: "",
      ColorFinish: "Stainless Steel",
      TotalNumberofPiecesIncluded: "10",
      PiecesIncluded:
        "Lid, Skillet / Frying Pan, Saucepan, Dutch Oven, Sauté Pan",
      SaucepanIncluded: "Yes",
      NumberofSaucepansIncluded: "2",
      FryingPanSkilletIncluded: "Yes",
      NumberofFryingPansSkilletsIncluded: "2",
      SautePanIncluded: "Yes",
      NumberofSautePansIncluded: "1",
      DutchOvenIncluded: "Yes",
      NumberofDutchOvensIncluded: "1",
      LidIncluded: "Yes",
      NumberofLidsIncluded: "4",
      LidMaterial: "Glass, Stainless Steel",
      NonStick: "Yes",
      NonStickLayers: "3",
      Plys: "3-Ply",
      OvenSafe: "Yes",
      MaximumTemperature: "500°F",
      ProductCare: "Dishwasher safe",
      ScratchResistant: "Yes",
      Stackable: "Yes",
      TechniqueWeave: "",
      Pattern: "",
      Location: "",
      NonSlipBacking: "",
      Reversible: "",
      BackingMaterial: "",
      Designer: "",
      Color: "",
      CommercialWarranty: "No",
      ProductWarranty: "Yes",
      WarrantyLength: "Lifetime",
      FullorLimitedWarranty: "Limited",
      WarrantyDetails:
        "Defects Only, Failure to follow recommended care will void product warranty, Consumer misuse not covered, Inspection Required, Normal wear and tear not covered",
      SOR2016176Compliant: "",
    },
    images: [
      "https://assets.wfcdn.com/im/11394406/resize-h800-w800%5Ecompr-r85/2511/251143230/Henckels+Clad+H3+10-Pc+Stainless+Steel+Cookware+Set.jpg",
      "https://assets.wfcdn.com/im/22745319/resize-h800-w800%5Ecompr-r85/2511/251143231/Henckels+Clad+H3+10-Pc+Stainless+Steel+Cookware+Set.jpg",
      "https://assets.wfcdn.com/im/34096232/resize-h800-w800%5Ecompr-r85/2511/251143232/Henckels+Clad+H3+10-Pc+Stainless+Steel+Cookware+Set.jpg",
    ],
  },
  {
    id: 4,
    name: "Cleobury Hall Tree 30'' Wide with Bench and Shoe Storage",
    reviews: "29",
    prePrice: "299.99",
    price: "202.99",
    sale: "32%",
    size: [],
    color: ["White", "Insignia Blue", "Autumn Driftwood"],
    description: {
      main: [
        "This hall tree brings both style and storage to your entryway or mudroom. It's made of engineered wood in a free-standing construction with wood grain distressing for a vintage feel. This hall tree features a clean-lined rectangular design with an open nook with four coat hooks and vertical panel details. Four open cubes at the base rest on tapered feet, while the flat top offers a seating area. We love that this hall tree comes with a tip-over restraint device to ensure stability. It stands at over 5', just right for most hallways.",
      ],
      inclued: [],
      features: [
        'Depth of the topcoat hanging part: 9.63"',
        "Four open cubes with adjustable shelves for spacious organization of shoes and bags of all sizes.",
        "The two-tone finish and planked seat surface create a rustic look",
      ],
      purposeful: [
        "Wood Scoring & Rub Through  (Technique used to give item a distressed look.)",
        "Wormholes(Small holes in the wood created with a spiked tool.)",
      ],
    },
    specification: {
      SaladPlate: "",
      DinnerPlate: "",
      Mug: "",
      IndividualCupMugWeight: "",
      DinnerPlateWeight: "",
      IndividualStorageCompartment: "6.5'' H X 12'' W X 14.5'' D",
      ServiceFor: "",
      PlateTypesIncluded: "",
      DinnerPlateIncluded: "",
      NumberofDinnerPlatesIncluded: "",

      SaladPlateIncluded: "",
      NumberofSaladPlatesIncluded: "",
      MainPlateShape: "",
      CerealBowlIncluded: "",
      NumberofCerealBowlsIncluded: "",
      AdditionalTablewareIncluded: "",
      AdditionalTypesofTablewareIncluded: "",
      ConstructionMethod: "",
      MugIncluded: "",
      NumberofMugsIncluded: "",
      RimDetail: "",
      FinishTreatment: "",
      BowlType: "",
      Overall: "67.25'' H X 30'' W X 15.5'' D",
      Seat: "",
      Leg: "",
      OverallProductWeight: "65.03 lb.",
      MinimumOverallHeightToptoBottom: "",
      BaseFinish: "",
      DistancebetweenEachHook: '5.5"',
      FrameMaterial: "Manufactured Wood",
      SeatMaterial: "",
      UpholsteryMaterial: "",
      WeightCapacity: "",
      StorageBenchIncluded: "Yes",
      ShelvesIncluded: "Yes",
      HooksIncluded: "Yes",
      NumberofHooksIncluded: "4",
      ShoeStorageIncluded: "Yes",
      IndividualCompartmentsIncluded: "Yes",
      MirrorIncluded: "No",
      TipoverRestraintDeviceIncluded: "Yes",
      MountType: "Free Standing",
      PurposefulDistressingType: "Wood Scoring & Rub Through; Wormholes",
      BenchMaterial: "Manufactured Wood",
      BenchWeightCapacity: "250 lb.",
      WeightCapacityPerHook: "100 lb.",
      NumberofShelves: "5",
      ProductType: "",
      ArcadeGameandSlotMachineFeatures: "",
      NumberofGamesIncluded: "",
      NumberofPlayers: "",
      PowerSource: "",
      PlugIn: "",
      AssemblyRequired: "Yes",
      AdditionalToolsRequired: "Phillips Head Screwdriver",
      AvoidPowerTools: "Yes",
      AssemblyInstallationStepsRequired: "",
      Material: "",
      BathroomCabinetIncluded: "",
      DoorsIncluded: "",
      Imported: "",
      Durability: "",
      HandleMaterial: "",
      DoorMaterial: "",
      SoftClosing: "",
      InstallationRequired: "",
      MountingInstallationType: "",
      ColorFinish: "",
      TotalNumberofPiecesIncluded: "",
      PiecesIncluded: "",
      SaucepanIncluded: "",
      NumberofSaucepansIncluded: "",
      FryingPanSkilletIncluded: "",
      NumberofFryingPansSkilletsIncluded: "",
      SautePanIncluded: "",
      NumberofSautePansIncluded: "",
      DutchOvenIncluded: "",
      NumberofDutchOvensIncluded: "",
      LidIncluded: "",
      NumberofLidsIncluded: "",
      LidMaterial: "",
      NonStick: "",
      NonStickLayers: "",
      Plys: "",
      OvenSafe: "",
      MaximumTemperature: "",
      ProductCare: "",
      ScratchResistant: "",
      Stackable: "",
      TechniqueWeave: "",
      Pattern: "",
      Location: "",
      NonSlipBacking: "",
      Reversible: "",
      BackingMaterial: "",
      Designer: "",
      Color: "",
      CommercialWarranty: "No",
      ProductWarranty: "Yes",
      WarrantyLength: "",
      FullorLimitedWarranty: "",
      WarrantyDetails: "",
      SOR2016176Compliant: "",
    },
    images: [
      "https://assets.wfcdn.com/im/93932195/resize-h800-w800%5Ecompr-r85/2869/286941440/Cleobury+Hall+Tree+30%27%27+Wide+with+Bench+and+Shoe+Storage.jpg",
      "https://assets.wfcdn.com/im/16634021/resize-h800-w800%5Ecompr-r85/2869/286941442/Cleobury+Hall+Tree+30%27%27+Wide+with+Bench+and+Shoe+Storage.jpg",
      "https://assets.wfcdn.com/im/22318884/resize-h800-w800%5Ecompr-r85/2425/242557464/Cleobury+Hall+Tree+30%27%27+Wide+with+Bench+and+Shoe+Storage.jpg",
      "https://assets.wfcdn.com/im/45596183/resize-h800-w800%5Ecompr-r85/2425/242557403/Cleobury+Hall+Tree+30%27%27+Wide+with+Bench+and+Shoe+Storage.jpg",
    ],
  },
  {
    id: 5,
    name: "Germell Over The Toilet Storage",
    reviews: "23",
    prePrice: "120.00",
    price: "152.99",
    sale: "25%",
    size: [],
    color: ["Coffee", "Ultramarrine", "Warm White"],
    description: {
      main: [
        "The Toilet Cabinet for Bathroom Storage, Over Toilet Bathroom Organizer with Art Glass Doors and Adjustable Shelf, Bathroom Storage Shelf Rack Above Toilet Storage Cabinet.",
      ],
      inclued: ["2 Door(s)", "Shelf/Shelves"],
      features: [
        "Maximize Your Bathroom's Potential: This over-the-toilet storage cabinet (69.3'' H x 27.95'' L x 8.47'' D) is designed to maximize your bathroom space without compromising on storage. It provides ample room for all your bathroom essentials, including toiletries, hand towels, and decorations, allowing you to keep your bathroom organized and clutter-free.",
        "Elegant and Transitional Style Design: Crafted from high-quality MDF board, with its crown molding and textured art glass door, our above toilet cabinet adds a touch of sophistication to your bathroom. Not only does the glass door enhance its premium appearance, but it also ensures increased privacy for your stored items, adding a sense of exclusivity to your bathroom space.",
        "Soft Close Hinges and Sturdy Structure: The over-toilet bathroom organizer features cushioned European hinges that enable quiet and smooth door closing, minimizing noise disruptions. The reinforced wooden bottom support provides sturdy stability, while the included anti-toppling wall mounting accessories offer added safety and security.",
        "Versatile and Durable: The adjustable shelf allows you to customize the storage space according to your needs. Additionally, the open shelf provides easy access to frequently used items or serves as a display area for your favorite bathroom decor. With its clean lines, atmospheric appearance, and hammered glass doors, it offers a crafted and elegant look that complements any bathroom, balcony, or laundry room.",
        "Easy Assembly and Dedicated Support: We understand the value of your time. That's why we've designed our over-toilet cabinet for easy assembly, providing clear instructions and numbered parts for a hassle-free setup experience.",
      ],
      purposeful: [],
    },
    specification: {
      SaladPlate: "",
      DinnerPlate: "",
      Mug: "",
      IndividualCupMugWeight: "",
      DinnerPlateWeight: "",
      IndividualStorageCompartment: "",
      ServiceFor: "",
      PlateTypesIncluded: "",
      DinnerPlateIncluded: "",
      NumberofDinnerPlatesIncluded: "",

      SaladPlateIncluded: "",
      NumberofSaladPlatesIncluded: "",
      MainPlateShape: "",
      CerealBowlIncluded: "",
      NumberofCerealBowlsIncluded: "",
      AdditionalTablewareIncluded: "",
      AdditionalTypesofTablewareIncluded: "",
      ConstructionMethod: "",
      MugIncluded: "",
      NumberofMugsIncluded: "",
      RimDetail: "",
      FinishTreatment: "",
      BowlType: "",
      Overall: "69.5'' H X 28'' W X 9'' D",
      Seat: "",
      Leg: "",
      OverallProductWeight: "45 lb.",
      MinimumOverallHeightToptoBottom: "",
      BaseFinish: "",
      Distancebetweeneachhook: "",
      FrameMaterial: "",
      SeatMaterial: "",
      UpholsteryMaterial: "",
      WeightCapacity: "",
      StorageBenchIncluded: "",
      ShelvesIncluded: "",
      HooksIncluded: "",
      NumberofHooksIncluded: "",
      ShoeStorageIncluded: "",
      IndividualCompartmentsIncluded: "",
      MirrorIncluded: "",
      TipoverRestraintDeviceIncluded: "",
      MountType: "",
      PurposefulDistressingType: "",
      BenchMaterial: "",
      BenchWeightCapacity: "",
      WeightCapacityPerHook: "",
      NumberofShelves: "",
      ProductType: "Over the Toilet Storage",
      ArcadeGameandSlotMachineFeatures: "",
      NumberofGamesIncluded: "",
      NumberofPlayers: "",
      PowerSource: "",
      PlugIn: "",
      AssemblyRequired: "",
      AdditionalToolsRequired: "",
      AvoidPowerTools: "",
      AssemblyInstallationStepsRequired: "",
      Material: "Manufactured Wood",
      BathroomCabinetIncluded: "Yes",
      DoorsIncluded: "Yes",
      Imported: "Yes",
      Durability: "Rust Resistant",
      HandleMaterial: "Aluminum",
      DoorMaterial: "Tempered Glass",
      SoftClosing: "Soft Close Door Hinges",
      InstallationRequired: "Yes",
      MountingInstallationType: "Freestanding, Wall",
      ColorFinish: "",
      TotalNumberofPiecesIncluded: "",
      PiecesIncluded: "",
      SaucepanIncluded: "",
      NumberofSaucepansIncluded: "",
      FryingPanSkilletIncluded: "",
      NumberofFryingPansSkilletsIncluded: "",
      SautePanIncluded: "",
      NumberofSautePansIncluded: "",
      DutchOvenIncluded: "",
      NumberofDutchOvensIncluded: "",
      LidIncluded: "",
      NumberofLidsIncluded: "",
      LidMaterial: "",
      NonStick: "",
      NonStickLayers: "",
      Plys: "",
      OvenSafe: "",
      MaximumTemperature: "",
      ProductCare: "",
      ScratchResistant: "",
      Stackable: "",
      TechniqueWeave: "",
      Pattern: "",
      Location: "",
      NonSlipBacking: "",
      Reversible: "",
      BackingMaterial: "",
      Designer: "",
      Color: "",
      CommercialWarranty: "",
      ProductWarranty: "Yes",
      WarrantyLength: "30 Days",
      FullorLimitedWarranty: "Limited",
      WarrantyDetails: "",
      SOR2016176Compliant: "",
    },
    images: [
      "https://assets.wfcdn.com/im/85259863/resize-h800-w800%5Ecompr-r85/2654/265449459/Germell+Over+The+Toilet+Storage%2C+Warm+White.jpg",
      "https://assets.wfcdn.com/im/79154949/resize-h800-w800%5Ecompr-r85/2654/265449488/Germell+Over+The+Toilet+Storage%2C+Warm+White.jpg",
      "https://assets.wfcdn.com/im/00457884/resize-h800-w800%5Ecompr-r85/2654/265449476/Germell+Over+The+Toilet+Storage%2C+Warm+White.jpg",
      "https://assets.wfcdn.com/im/89106971/resize-h800-w800%5Ecompr-r85/2654/265449477/Germell+Over+The+Toilet+Storage%2C+Warm+White.jpg",
      "https://assets.wfcdn.com/im/89106971/resize-h800-w800%5Ecompr-r85/2654/265449477/Germell+Over+The+Toilet+Storage%2C+Warm+White.jpg",
      "https://assets.wfcdn.com/im/30507073/resize-h800-w800%5Ecompr-r85/2654/265449460/Germell+Over+The+Toilet+Storage%2C+Warm+White.jpg",
      "https://assets.wfcdn.com/im/63544909/resize-h800-w800%5Ecompr-r85/2493/249373150/Germell+Over+The+Toilet+Storage%2C+Coffee.jpg",
      "https://assets.wfcdn.com/im/06966645/resize-h800-w800%5Ecompr-r85/3002/300222199/Germell+Over+The+Toilet+Storage%2C+Ultramarrine.jpg",
    ],
  },
  {
    id: 6,
    name: "16 Piece Stoneware Dinnerware Set - Service for 4",
    reviews: "171",
    prePrice: "95.99",
    price: "39.99",
    sale: "58%",
    size: [],
    color: [],
    description: {
      main: [
        "This colorful Tuscan-inspired 16-piece dinnerware set (service for four) will add a hint of European flair to your table. It includes four dinner plates, four salad plates, four cereal bowls, and four mugs all made from BPA-free stoneware. Each piece features a blue, red, yellow, and green medallion pattern that adds a boost of brightness to your dinner. This set is microwave and oven safe so you can reheat leftovers with ease. Plus, when dinner is done, all you have to do is add the dishes to the dishwasher.",
      ],
      inclued: [
        "4 Mug",
        "Additional Items",
        "4 Cereal Bowl",
        "4 Dinner Plate",
        "4 Salad Plate",
      ],
      features: [
        "Made of durable stoneware",
        "24 oz bowl capacity",
        "15 oz mug capacity",
      ],
      purposeful: [],
    },
    specification: {
      SaladPlate: "8.54'' W",
      DinnerPlate: "10.7'' W",
      Mug: "3.3'' W",
      IndividualCupMugWeight: "0.7 lb.",
      DinnerPlateWeight: "1.6 lb.",
      IndividualStorageCompartment: "",
      ServiceFor: "4",
      PlateTypesIncluded: "Dinner Plate, Salad Plate",
      DinnerPlateIncluded: "Yes",
      NumberofDinnerPlatesIncluded: "4",
      SaladPlateIncluded: "Yes",
      NumberofSaladPlatesIncluded: "4",
      MainPlateShape: "Round",
      CerealBowlIncluded: "Yes",
      NumberofCerealBowlsIncluded: "4",
      AdditionalTablewareIncluded: "Yes",
      AdditionalTypesofTablewareIncluded: "Mug",
      ConstructionMethod: "Semi-Handmade",
      MugIncluded: "Yes",
      NumberofMugsIncluded: "4",
      RimDetail: "Color Rim",
      FinishTreatment: "Gloss",
      BowlType: "Cereal Bowl",
      Overall: "",
      Seat: "",
      Leg: "",
      OverallProductWeight: "16.8 lb.",
      MinimumOverallHeightToptoBottom: "",
      BaseFinish: "",
      Distancebetweeneachhook: "",
      FrameMaterial: "",
      SeatMaterial: "",
      UpholsteryMaterial: "",
      WeightCapacity: "",
      StorageBenchIncluded: "",
      ShelvesIncluded: "",
      HooksIncluded: "",
      NumberofHooksIncluded: "",
      ShoeStorageIncluded: "",
      IndividualCompartmentsIncluded: "",
      MirrorIncluded: "",
      TipoverRestraintDeviceIncluded: "",
      MountType: "",
      PurposefulDistressingType: "",
      BenchMaterial: "",
      BenchWeightCapacity: "",
      WeightCapacityPerHook: "",
      NumberofShelves: "",
      ProductType: "",
      ArcadeGameandSlotMachineFeatures: "",
      NumberofGamesIncluded: "",
      NumberofPlayers: "",
      PowerSource: "",
      PlugIn: "",
      AssemblyRequired: "",
      AdditionalToolsRequired: "",
      AvoidPowerTools: "",
      AssemblyInstallationStepsRequired: "",
      Material: "Stoneware",
      BathroomCabinetIncluded: "",
      DoorsIncluded: "",
      Imported: "",
      Durability: "",
      HandleMaterial: "",
      DoorMaterial: "",
      SoftClosing: "",
      InstallationRequired: "",
      MountingInstallationType: "",
      ColorFinish: "",
      TotalNumberofPiecesIncluded: "16",
      PiecesIncluded: "",
      SaucepanIncluded: "",
      NumberofSaucepansIncluded: "",
      FryingPanSkilletIncluded: "",
      NumberofFryingPansSkilletsIncluded: "",
      SautePanIncluded: "",
      NumberofSautePansIncluded: "",
      DutchOvenIncluded: "",
      NumberofDutchOvensIncluded: "",
      LidIncluded: "",
      NumberofLidsIncluded: "",
      LidMaterial: "",
      NonStick: "",
      NonStickLayers: "",
      Plys: "",
      OvenSafe: "",
      MaximumTemperature: "",
      ProductCare: "Dishwasher safe",
      ScratchResistant: "",
      Stackable: "",
      TechniqueWeave: "",
      Pattern: "Floral",
      Location: "",
      NonSlipBacking: "",
      Reversible: "",
      BackingMaterial: "",
      Designer: "",
      Color: "",
      CommercialWarranty: "No",
      ProductWarranty: "",
      WarrantyLength: "",
      FullorLimitedWarranty: "",
      WarrantyDetails: "",
      SOR2016176Compliant: "",
    },
    images: [
      "https://assets.wfcdn.com/im/94438215/resize-h800-w800%5Ecompr-r85/2451/245136919/16+Piece+Stoneware+Dinnerware+Set+-+Service+for+4.jpg",
      "https://assets.wfcdn.com/im/70360572/resize-h800-w800%5Ecompr-r85/1206/120622291/16+Piece+Stoneware+Dinnerware+Set+-+Service+for+4.jpg",
      "https://assets.wfcdn.com/im/69092550/resize-h800-w800%5Ecompr-r85/2616/261635646/16+Piece+Stoneware+Dinnerware+Set+-+Service+for+4.jpg",
    ],
  },
  {
    id: 7,
    name: "Loriane Adjustable Wobble Chair, Active Stools for Classroom ADHD/ADD Childs, Office Standing Desk",
    reviews: "5",
    prePrice: "235.99",
    price: "155.99",
    sale: "34%",
    size: [],
    color: ["Black", "Blue", "Green", "Purple", "Red"],
    description: {
      main: [
        "This wiggle seat is a great alternative to traditional desk stools or classroom furniture, also suitable for office standing desk stools. This wobble chair features of ABS frame and a circular base covering a most-stretch non-pilling fabric, that allows you to lean in different directions from 360° rotation and 50° Right /Left. Our wobble stools allow kids to move continuously without ever leaving their desks. This freedom of motion promotes relaxation and focus, leading to more engaging and entertaining learning. Unlike traditional classroom seating/office chairs, this wobble seat prevents slouching, improves spine position, and strengthens the core and back. The round active stool seat is upholstered and filled with foam for just the right amount of support as you sit. Plus, the gas lift height (21”-25” Height) of the wobble chair using the ring under the seat and swivel a full 360°.",
      ],
      inclued: [],
      features: [
        "Helps kids stay seated longer - Wobble chairs encourage children with high energy, sensory challenges, or ADD/ADHD to get their wiggles out while remaining in their seats, helping them stay seated longer and focus better.",
        "Safe protection with TÜV Rheinland Certified, UL Certified: A patented TÜV Rheinland Certified, UL Certified for this wobble stool, its' sturdy ring on the base prevents tip-overs and falls. The gentle-rounded base is stable, moves with your body, and rests naturally upright.",
        "360° Wobble Seater Right/Left: By wobbling right to left or front to back,360°rotating the wobble chair to swivel your body, the Wobble Chair provides an energy outlet tool, and also can help you relax the cartilage to focus working.",
        "Perfect Partner for Your Standing Desk - Elevate your productivity and comfort with our tall stool for your standing desk. The wobble stool is a balance stool that lets you rest at a standing height, reducing leg fatigue without sacrificing posture.",
        'Premium Comfort, Cool Design - Our wobble chair boasts a spacious, cushioned seat with edgeless, fabric-covered perfection. Enjoy 3 inches of premium foam cushioning on this ergonomic standing chair for a comfortable and stylish seating solution. Balance and Stability at Its Best - The counter-balanced active chair rests naturally upright, providing stability without the constant need for balancing. The height-adjustable wobble stool simply adjusts from 21-25" Height with the press of a button/thread. Your floors are safe with our non-skid, non-scratch rubberized coating.',
      ],
      purposeful: [],
    },
    specification: {
      SaladPlate: "",
      DinnerPlate: "",
      Mug: "",
      IndividualCupMugWeight: "",
      DinnerPlateWeight: "",
      IndividualStorageCompartment: "",
      ServiceFor: "",
      PlateTypesIncluded: "",
      DinnerPlateIncluded: "",
      NumberofDinnerPlatesIncluded: "",
      SaladPlateIncluded: "",
      NumberofSaladPlatesIncluded: "",
      MainPlateShape: "",
      CerealBowlIncluded: "",
      NumberofCerealBowlsIncluded: "",
      AdditionalTablewareIncluded: "",
      AdditionalTypesofTablewareIncluded: "",
      ConstructionMethod: "",
      MugIncluded: "es",
      NumberofMugsIncluded: "",
      RimDetail: "",
      FinishTreatment: "Glss",
      BowlType: "",
      Overall: "14.96'' W X 14.96'' D",
      Seat: "2.75'' H X 14.96'' W X 14.96'' D",
      Leg: "12.2'' H",
      OverallProductWeight: "16.28 lb.",
      MinimumOverallHeightToptoBottom: "21'' H",
      MaximumOverallHeightToptoBottom: "25'' H",
      BaseFinish: "Matte",
      Distancebetweeneachhook: "",
      FrameMaterial: "",
      SeatMaterial: "",
      UpholsteryMaterial: "Foam",
      WeightCapacity: "",
      StorageBenchIncluded: "",
      ShelvesIncluded: "",
      HooksIncluded: "",
      NumberofHooksIncluded: "",
      ShoeStorageIncluded: "",
      IndividualCompartmentsIncluded: "",
      MirrorIncluded: "",
      TipoverRestraintDeviceIncluded: "",
      MountType: "",
      PurposefulDistressingType: "",
      BenchMaterial: "",
      BenchWeightCapacity: "",
      WeightCapacityPerHook: "",
      NumberofShelves: "",
      ProductType: "Active Stool",
      ArcadeGameandSlotMachineFeatures: "",
      NumberofGamesIncluded: "",
      NumberofPlayers: "",
      PowerSource: "",
      PlugIn: "",
      AssemblyRequired: "",
      AdditionalToolsRequired: "",
      AvoidPowerTools: "",
      AssemblyInstallationStepsRequired: "",
      Material: "",
      BathroomCabinetIncluded: "",
      DoorsIncluded: "",
      Imported: "Yes",
      Durability: "",
      HandleMaterial: "",
      DoorMaterial: "",
      SoftClosing: "",
      InstallationRequired: "",
      MountingInstallationType: "",
      ColorFinish: "",
      TotalNumberofPiecesIncluded: "",
      PiecesIncluded: "",
      SaucepanIncluded: "",
      NumberofSaucepansIncluded: "",
      FryingPanSkilletIncluded: "",
      NumberofFryingPansSkilletsIncluded: "",
      SautePanIncluded: "",
      NumberofSautePansIncluded: "",
      DutchOvenIncluded: "",
      NumberofDutchOvensIncluded: "",
      LidIncluded: "",
      NumberofLidsIncluded: "",
      LidMaterial: "",
      NonStick: "",
      NonStickLayers: "",
      Plys: "",
      OvenSafe: "",
      MaximumTemperature: "",
      ProductCare: "Do not use bleach, Neutral Ph Cleaner",
      ScratchResistant: "",
      Stackable: "",
      TechniqueWeave: "",
      Pattern: "",
      Location: "",
      NonSlipBacking: "",
      Reversible: "",
      BackingMaterial: "",
      Designer: "",
      Color: "",
      CommercialWarranty: "Yes",
      ProductWarranty: "Yes",
      WarrantyLength: "1 Year",
      FullorLimitedWarranty: "Limited",
      WarrantyDetails: "",
      SOR2016176Compliant: "",
    },
    images: [
      "https://assets.wfcdn.com/im/55387114/resize-h800-w800%5Ecompr-r85/2617/261769729/Loriane+Adjustable+Wobble+Chair%2C+Active+Stools+for+Classroom+ADHD%2FADD+Childs%2C+Office+Standing+Desk%2C+Black.jpg",
      "https://assets.wfcdn.com/im/13987012/resize-h800-w800%5Ecompr-r85/2617/261769712/Loriane+Adjustable+Wobble+Chair%2C+Active+Stools+for+Classroom+ADHD%2FADD+Childs%2C+Office+Standing+Desk%2C+Black.jpg",
      "https://assets.wfcdn.com/im/24225719/resize-h800-w800%5Ecompr-r85/2617/261769717/Loriane+Adjustable+Wobble+Chair%2C+Active+Stools+for+Classroom+ADHD%2FADD+Childs%2C+Office+Standing+Desk%2C+Black.jpg",
    ],
  },
];

export default productData;
