import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [activeIndex, setActiveIndex] = useState(null);
  const [quantities, setQuantities] = useState(1);
  const { addToCart } = useContext(CartContext);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const toggleDisplay = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleAddToCart = () => {
    if (product.size.length > 0 && !selectedSize) {
      alert("Please select a size!");
      return;
    }
    if (product.color.length > 0 && !selectedColor) {
      alert("Please select a color!");
      return;
    }

    const options = {
      size: selectedSize || null,
      color: selectedColor || null,
    };

    addToCart(product.id, quantities, options.size, options.color);
    navigate("/cartpage");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const increaseQuantity = () => {
    setQuantities(quantities + 1);
  };
  const decreaseQuantity = () => {
    if (quantities > 1) {
      setQuantities(quantities - 1);
    }
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div
        className="mainBox"
        style={{
          borderBottom: "1px solid rgb(37 37 37 / 15%)",
        }}
      >
        <div className="detail_box">
          <div className="iphone_nav">
            <nav>
              <ol>
                <li>
                  <Link to="/">Home </Link>
                </li>
                <li>
                  <img
                    width="24"
                    height="24"
                    src="https://img.icons8.com/material-sharp/50/forward.png"
                    alt="forward"
                  />
                </li>
                <li>{product.name}</li>
              </ol>
            </nav>
          </div>

          <div className="item_main_box">
            <div className="item_main_box_left">
              <div className="product-images">
                <div className="carousel">
                  {product.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`${product.name}`}
                      style={{
                        display: index === currentIndex ? "block" : "none",
                      }}
                    />
                  ))}
                  <div>
                    <span className="product_early">
                      <span className="product_early_inner">
                        Early Black Friday Deal
                      </span>
                    </span>
                  </div>
                </div>
                <div className="carousel-buttons">
                  {product.images.map((image, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentIndex(index)}
                      style={{
                        border:
                          currentIndex === index
                            ? "2px solid #7b189f"
                            : "2px solid transparent",
                      }}
                    >
                      <img src={image} alt={`${product.name}`} />
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="item_main_box_right">
              <div className="item_main_box_right_inner">
                <h1>{product.name}</h1>
                <div className="rating_review">
                  <span className="ProductRatingNumberWithCount-rating">
                    4.6
                  </span>
                  <a className="item_reviews" alt="" href="/cartpage">
                    667 Reviews
                  </a>
                </div>
                <div className="item_right_price">
                  <div className="item_right_price_inner">
                    <span className="item_right_price_span_one">
                      ${product.price}
                    </span>
                    <s
                      aria-label="was $69.00"
                      className="item_right_price_span_one_s"
                    >
                      ${product.prePrice}
                    </s>
                    &nbsp; &nbsp;
                    <span className="item_right_price_span_two">
                      {product.sale} Off
                    </span>
                  </div>
                  <div
                    className="item_right_price_inner"
                    style={{ marginTop: "6px" }}
                  >
                    <span className="item_right_price_span_one">On Sale</span>
                  </div>
                </div>
                {product.size.length > 0 && (
                  <div className="item_right_size">
                    <label>Size</label>
                    <select
                      value={selectedSize}
                      onChange={(e) => setSelectedSize(e.target.value)}
                    >
                      <option value="">Please select the size</option>
                      {product.size.map((size, index) => (
                        <>
                          <option key={index} value={size}>
                            {size}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                )}
                {product.color.length > 0 && (
                  <div className="item_right_size">
                    <label>Color</label>
                    <select
                      value={selectedColor}
                      onChange={(e) => setSelectedColor(e.target.value)}
                    >
                      <option value="">Please select the color</option>
                      {product.color.map((color, index) => (
                        <>
                          <option key={index} value={color}>
                            {color}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                )}
                <div className="item_right_quanty_cart">
                  <div className="item_right_quanty">
                    <button
                      className="btn--minus"
                      name="minus"
                      type="button"
                      onClick={decreaseQuantity}
                    >
                      <svg
                        focusable="false"
                        viewBox="2 2 24 24"
                        aria-hidden="true"
                        className="_16q98ey0_6112 _16q98ey2_6112 _16q98ey1_6112 pl-NumericStepper-buttonIcon"
                        data-hb-id="pl-icon"
                      >
                        <path d="M19.5 14.5h-11a.5.5 0 010-1h11a.5.5 0 010 1z"></path>
                      </svg>
                    </button>

                    <input
                      type="text"
                      name="quantities"
                      value={quantities}
                      min="1"
                    />
                    <button
                      type="button"
                      className="btn--plus"
                      name="plus"
                      onClick={increaseQuantity}
                    >
                      <svg
                        focusable="false"
                        viewBox="2 2 24 24"
                        aria-hidden="true"
                        className="_16q98ey0_6112 _16q98ey2_6112 _16q98ey1_6112 pl-NumericStepper-buttonIcon"
                        data-hb-id="pl-icon"
                      >
                        <path d="M20 13.5h-5.5V8a.5.5 0 00-1 0v5.5H8a.5.5 0 000 1h5.5V20a.5.5 0 001 0v-5.5H20a.5.5 0 000-1z"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="item_right_add">
                    <button
                      className="btn btn--primary w-full"
                      name="add"
                      onClick={() => handleAddToCart()}
                    >
                      Add to cart
                    </button>
                  </div>
                </div>

                <div className="item_details">
                  <div className="item_details_inner">
                    <div className="item_details_internal">
                      <h2>Description</h2>
                      <span className="disclosure__toggle">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                          role="presentation"
                          className="icon"
                          onClick={() => toggleDisplay(1)}
                          style={{
                            transform:
                              activeIndex === 1
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition: "transform 0.3s ease",
                          }}
                        >
                          <path
                            d="M20 8.5 12.5 16 5 8.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            fill="none"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div
                      className="item_details_body"
                      style={{
                        display: activeIndex === 1 ? "block" : "none",
                      }}
                    >
                      <p style={{ marginTop: "2em" }}>
                        {product.description.main}
                      </p>
                      <p>
                        <br />
                      </p>
                      {product.description.features > 0 && (
                        <>
                          <p>
                            <strong>Features:&nbsp;</strong>
                          </p>
                          <ul>
                            {product.description.features.map(
                              (feature, index) => (
                                <li key={index}>{feature}</li>
                              )
                            )}
                          </ul>
                          <p>
                            <br />
                          </p>
                        </>
                      )}

                      {product.description.inclued > 0 && (
                        <>
                          <p>
                            <strong>Include: &nbsp;</strong>
                          </p>
                          <ul>
                            {product.description.inclued.map(
                              (inclued, index) => (
                                <li key={index}>{inclued}</li>
                              )
                            )}
                          </ul>{" "}
                          <p>
                            <br />
                          </p>
                        </>
                      )}

                      {product.description.purposeful > 0 && (
                        <>
                          <p>
                            <strong>Purposeful: &nbsp;</strong>
                          </p>
                          <ul>
                            {product.description.purposeful.map(
                              (purposeful, index) => (
                                <li key={index}>{purposeful}</li>
                              )
                            )}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className="item_details_inner"
                    style={{ marginBottom: "2rem" }}
                  >
                    <div className="item_details_internal">
                      <h2>Specifications</h2>
                      <span className="disclosure__toggle">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                          role="presentation"
                          className="icon"
                          onClick={() => toggleDisplay(2)}
                          style={{
                            transform:
                              activeIndex === 2
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition: "transform 0.3s ease",
                          }}
                        >
                          <path
                            d="M20 8.5 12.5 16 5 8.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            fill="none"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div
                      className="item_details_body"
                      style={{
                        display: activeIndex === 2 ? "block" : "none",
                        marginTop: "2em",
                      }}
                    >
                      {product.specification.SaladPlate && (
                        <div className="item_body_flex">
                          <div className="left-col">Salad Plate</div>
                          <div className="right-col">
                            {product.specification.SaladPlate}
                          </div>
                        </div>
                      )}
                      {product.specification.DinnerPlate && (
                        <div className="item_body_flex">
                          <div className="left-col">Dinner Plate</div>
                          <div className="right-col">
                            {product.specification.DinnerPlate}
                          </div>
                        </div>
                      )}
                      {product.specification.Mug && (
                        <div className="item_body_flex">
                          <div className="left-col">Mug</div>
                          <div className="right-col">
                            {product.specification.Mug}
                          </div>
                        </div>
                      )}
                      {product.specification.IndividualCupMugWeight && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Individual Cup Mug Weight
                          </div>
                          <div className="right-col">
                            {product.specification.IndividualCupMugWeight}
                          </div>
                        </div>
                      )}
                      {product.specification.DinnerPlateWeight && (
                        <div className="item_body_flex">
                          <div className="left-col">Dinner Plate Weight</div>
                          <div className="right-col">
                            {product.specification.DinnerPlateWeight}
                          </div>
                        </div>
                      )}
                      {product.specification.IndividualStorageCompartment && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Individual Storage Compartment
                          </div>
                          <div className="right-col">
                            {product.specification.IndividualStorageCompartment}
                          </div>
                        </div>
                      )}
                      {product.specification.ServiceFor && (
                        <div className="item_body_flex">
                          <div className="left-col">Service For</div>
                          <div className="right-col">
                            {product.specification.ServiceFor}
                          </div>
                        </div>
                      )}
                      {product.specification.PlateTypesIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Plate Types Included</div>
                          <div className="right-col">
                            {product.specification.PlateTypesIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.DinnerPlateIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Dinner Plate Included</div>
                          <div className="right-col">
                            {product.specification.DinnerPlateIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.NumberofDinnerPlatesIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Dinner Plates Included
                          </div>
                          <div className="right-col">
                            {product.specification.NumberofDinnerPlatesIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.SaladPlateIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Salad Plate Included</div>
                          <div className="right-col">
                            {product.specification.SaladPlateIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.NumberofSaladPlatesIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Salad Plates Included
                          </div>
                          <div className="right-col">
                            {product.specification.NumberofSaladPlatesIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.MainPlateShape && (
                        <div className="item_body_flex">
                          <div className="left-col">Main Plate Shape</div>
                          <div className="right-col">
                            {product.specification.MainPlateShape}
                          </div>
                        </div>
                      )}
                      {product.specification.CerealBowlIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Cereal Bowl Included</div>
                          <div className="right-col">
                            {product.specification.CerealBowlIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.NumberofCerealBowlsIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Cereal Bowls Included
                          </div>
                          <div className="right-col">
                            {product.specification.NumberofCerealBowlsIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.AdditionalTablewareIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Additional Tableware Included
                          </div>
                          <div className="right-col">
                            {product.specification.AdditionalTablewareIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification
                        .AdditionalTypesofTablewareIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Additional Types of Tableware Included
                          </div>
                          <div className="right-col">
                            {
                              product.specification
                                .AdditionalTypesofTablewareIncluded
                            }
                          </div>
                        </div>
                      )}
                      {product.specification.ConstructionMethod && (
                        <div className="item_body_flex">
                          <div className="left-col">Construction Method</div>
                          <div className="right-col">
                            {product.specification.ConstructionMethod}
                          </div>
                        </div>
                      )}
                      {product.specification.MugIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Mug Included</div>
                          <div className="right-col">
                            {product.specification.MugIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.NumberofMugsIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Mugs Included
                          </div>
                          <div className="right-col">
                            {product.specification.NumberofMugsIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.RimDetail && (
                        <div className="item_body_flex">
                          <div className="left-col">Rim Detail</div>
                          <div className="right-col">
                            {product.specification.RimDetail}
                          </div>
                        </div>
                      )}
                      {product.specification.FinishTreatment && (
                        <div className="item_body_flex">
                          <div className="left-col">Finish Treatment</div>
                          <div className="right-col">
                            {product.specification.FinishTreatment}
                          </div>
                        </div>
                      )}
                      {product.specification.BowlType && (
                        <div className="item_body_flex">
                          <div className="left-col">Bowl Type</div>
                          <div className="right-col">
                            {product.specification.BowlType}
                          </div>
                        </div>
                      )}
                      {product.specification.Overall && (
                        <div className="item_body_flex">
                          <div className="left-col">Overall</div>
                          <div className="right-col">
                            {product.specification.Overall}
                          </div>
                        </div>
                      )}
                      {product.specification.Seat && (
                        <div className="item_body_flex">
                          <div className="left-col">Seat</div>
                          <div className="right-col">
                            {product.specification.Seat}
                          </div>
                        </div>
                      )}
                      {product.specification.Leg && (
                        <div className="item_body_flex">
                          <div className="left-col">Leg</div>
                          <div className="right-col">
                            {product.specification.Leg}
                          </div>
                        </div>
                      )}
                      {product.specification.OverallProductWeight && (
                        <div className="item_body_flex">
                          <div className="left-col">Overall Product Weight</div>
                          <div className="right-col">
                            {product.specification.OverallProductWeight}
                          </div>
                        </div>
                      )}
                      {product.specification
                        .MinimumOverallHeightToptoBottom && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Minimum Overall Height (Top to Bottom)
                          </div>
                          <div className="right-col">
                            {
                              product.specification
                                .MinimumOverallHeightToptoBottom
                            }
                          </div>
                        </div>
                      )}
                      {product.specification.BaseFinish && (
                        <div className="item_body_flex">
                          <div className="left-col">Base Finish</div>
                          <div className="right-col">
                            {product.specification.BaseFinish}
                          </div>
                        </div>
                      )}
                      {product.specification.Distancebetweeneachhook && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Distance between each hook
                          </div>
                          <div className="right-col">
                            {product.specification.Distancebetweeneachhook}
                          </div>
                        </div>
                      )}
                      {product.specification.FrameMaterial && (
                        <div className="item_body_flex">
                          <div className="left-col">Frame Material</div>
                          <div className="right-col">
                            {product.specification.FrameMaterial}
                          </div>
                        </div>
                      )}
                      {product.specification.SeatMaterial && (
                        <div className="item_body_flex">
                          <div className="left-col">Seat Material</div>
                          <div className="right-col">
                            {product.specification.SeatMaterial}
                          </div>
                        </div>
                      )}
                      {product.specification.UpholsteryMaterial && (
                        <div className="item_body_flex">
                          <div className="left-col">Upholstery Material</div>
                          <div className="right-col">
                            {product.specification.UpholsteryMaterial}
                          </div>
                        </div>
                      )}
                      {product.specification.WeightCapacity && (
                        <div className="item_body_flex">
                          <div className="left-col">Weight Capacity</div>
                          <div className="right-col">
                            {product.specification.WeightCapacity}
                          </div>
                        </div>
                      )}
                      {product.specification.StorageBenchIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Storage Bench Included</div>
                          <div className="right-col">
                            {product.specification.StorageBenchIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.ShelvesIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Shelves Included</div>
                          <div className="right-col">
                            {product.specification.ShelvesIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.HooksIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Hooks Included</div>
                          <div className="right-col">
                            {product.specification.HooksIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.NumberofHooksIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Hooks Included
                          </div>
                          <div className="right-col">
                            {product.specification.NumberofHooksIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.ShoeStorageIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Shoe Storage Included</div>
                          <div className="right-col">
                            {product.specification.ShoeStorageIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.IndividualCompartmentsIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Individual Compartments Included
                          </div>
                          <div className="right-col">
                            {
                              product.specification
                                .IndividualCompartmentsIncluded
                            }
                          </div>
                        </div>
                      )}
                      {product.specification.MirrorIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Mirror Included</div>
                          <div className="right-col">
                            {product.specification.MirrorIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.TipoverRestraintDeviceIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Tip-over Restraint Device Included
                          </div>
                          <div className="right-col">
                            {
                              product.specification
                                .TipoverRestraintDeviceIncluded
                            }
                          </div>
                        </div>
                      )}
                      {product.specification.MountType && (
                        <div className="item_body_flex">
                          <div className="left-col">Mount Type</div>
                          <div className="right-col">
                            {product.specification.MountType}
                          </div>
                        </div>
                      )}
                      {product.specification.BenchMaterial && (
                        <div className="item_body_flex">
                          <div className="left-col">Bench Material</div>
                          <div className="right-col">
                            {product.specification.BenchMaterial}
                          </div>
                        </div>
                      )}
                      {product.specification.BenchWeightCapacity && (
                        <div className="item_body_flex">
                          <div className="left-col">Bench Weight Capacity</div>
                          <div className="right-col">
                            {product.specification.BenchWeightCapacity}
                          </div>
                        </div>
                      )}
                      {product.specification.WeightCapacityPerHook && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Weight Capacity Per Hook
                          </div>
                          <div className="right-col">
                            {product.specification.WeightCapacityPerHook}
                          </div>
                        </div>
                      )}
                      {product.specification.NumberofShelves && (
                        <div className="item_body_flex">
                          <div className="left-col">Number of Shelves</div>
                          <div className="right-col">
                            {product.specification.NumberofShelves}
                          </div>
                        </div>
                      )}
                      {product.specification.ProductType && (
                        <div className="item_body_flex">
                          <div className="left-col">Product Type</div>
                          <div className="right-col">
                            {product.specification.ProductType}
                          </div>
                        </div>
                      )}
                      {product.specification
                        .ArcadeGameandSlotMachineFeatures && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Arcade Game and Slot Machine Features
                          </div>
                          <div className="right-col">
                            {
                              product.specification
                                .ArcadeGameandSlotMachineFeatures
                            }
                          </div>
                        </div>
                      )}
                      {product.specification.NumberofGamesIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Games Included
                          </div>
                          <div className="right-col">
                            {product.specification.NumberofGamesIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.NumberofPlayers && (
                        <div className="item_body_flex">
                          <div className="left-col">Number of Players</div>
                          <div className="right-col">
                            {product.specification.NumberofPlayers}
                          </div>
                        </div>
                      )}
                      {product.specification.PowerSource && (
                        <div className="item_body_flex">
                          <div className="left-col">Power Source</div>
                          <div className="right-col">
                            {product.specification.PowerSource}
                          </div>
                        </div>
                      )}
                      {product.specification.PlugIn && (
                        <div className="item_body_flex">
                          <div className="left-col">Plug-In</div>
                          <div className="right-col">
                            {product.specification.PlugIn}
                          </div>
                        </div>
                      )}
                      {product.specification.AssemblyRequired && (
                        <div className="item_body_flex">
                          <div className="left-col">Assembly Required</div>
                          <div className="right-col">
                            {product.specification.AssemblyRequired}
                          </div>
                        </div>
                      )}
                      {product.specification.AdditionalToolsRequired && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Additional Tools Required
                          </div>
                          <div className="right-col">
                            {product.specification.AdditionalToolsRequired}
                          </div>
                        </div>
                      )}
                      {product.specification.AvoidPowerTools && (
                        <div className="item_body_flex">
                          <div className="left-col">Avoid Power Tools</div>
                          <div className="right-col">
                            {product.specification.AvoidPowerTools}
                          </div>
                        </div>
                      )}
                      {product.specification
                        .AssemblyInstallationStepsRequired && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Assembly Installation Steps Required
                          </div>
                          <div className="right-col">
                            {
                              product.specification
                                .AssemblyInstallationStepsRequired
                            }
                          </div>
                        </div>
                      )}
                      {product.specification.Material && (
                        <div className="item_body_flex">
                          <div className="left-col">Material</div>
                          <div className="right-col">
                            {product.specification.Material}
                          </div>
                        </div>
                      )}
                      {product.specification.BathroomCabinetIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Bathroom Cabinet Included
                          </div>
                          <div className="right-col">
                            {product.specification.BathroomCabinetIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.DoorsIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Doors Included</div>
                          <div className="right-col">
                            {product.specification.DoorsIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.Imported && (
                        <div className="item_body_flex">
                          <div className="left-col">Imported</div>
                          <div className="right-col">
                            {product.specification.Imported}
                          </div>
                        </div>
                      )}
                      {product.specification.Durability && (
                        <div className="item_body_flex">
                          <div className="left-col">Durability</div>
                          <div className="right-col">
                            {product.specification.Durability}
                          </div>
                        </div>
                      )}
                      {product.specification.HandleMaterial && (
                        <div className="item_body_flex">
                          <div className="left-col">Handle Material</div>
                          <div className="right-col">
                            {product.specification.HandleMaterial}
                          </div>
                        </div>
                      )}
                      {product.specification.DoorMaterial && (
                        <div className="item_body_flex">
                          <div className="left-col">Door Material</div>
                          <div className="right-col">
                            {product.specification.DoorMaterial}
                          </div>
                        </div>
                      )}
                      {product.specification.SoftClosing && (
                        <div className="item_body_flex">
                          <div className="left-col">Soft Closing</div>
                          <div className="right-col">
                            {product.specification.SoftClosing}
                          </div>
                        </div>
                      )}
                      {product.specification.InstallationRequired && (
                        <div className="item_body_flex">
                          <div className="left-col">Installation Required</div>
                          <div className="right-col">
                            {product.specification.InstallationRequired}
                          </div>
                        </div>
                      )}
                      {product.specification.MountingInstallationType && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Mounting Installation Type
                          </div>
                          <div className="right-col">
                            {product.specification.MountingInstallationType}
                          </div>
                        </div>
                      )}
                      {product.specification.ColorFinish && (
                        <div className="item_body_flex">
                          <div className="left-col">Color/Finish</div>
                          <div className="right-col">
                            {product.specification.ColorFinish}
                          </div>
                        </div>
                      )}
                      {product.specification.TotalNumberofPiecesIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Total Number of Pieces Included
                          </div>
                          <div className="right-col">
                            {product.specification.TotalNumberofPiecesIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.PiecesIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Pieces Included</div>
                          <div className="right-col">
                            {product.specification.PiecesIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.SaucepanIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Saucepan Included</div>
                          <div className="right-col">
                            {product.specification.SaucepanIncluded}
                          </div>
                        </div>
                      )}
                      {product.specification.NumberofSaucepansIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Saucepans Included
                          </div>
                          <div className="right-col">
                            {product.specification.NumberofSaucepansIncluded}
                          </div>
                        </div>
                      )}

                      {product.specification.FryingPanSkilletIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Frying Pan/Skillet Included
                          </div>
                          <div className="right-col">
                            {product.specification.FryingPanSkilletIncluded}
                          </div>
                        </div>
                      )}

                      {product.specification
                        .NumberofFryingPansSkilletsIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Frying Pans/Skillets Included
                          </div>
                          <div className="right-col">
                            {
                              product.specification
                                .NumberofFryingPansSkilletsIncluded
                            }
                          </div>
                        </div>
                      )}

                      {product.specification.SautePanIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Saute Pan Included</div>
                          <div className="right-col">
                            {product.specification.SautePanIncluded}
                          </div>
                        </div>
                      )}

                      {product.specification.NumberofSautePansIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Saute Pans Included
                          </div>
                          <div className="right-col">
                            {product.specification.NumberofSautePansIncluded}
                          </div>
                        </div>
                      )}

                      {product.specification.DutchOvenIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Dutch Oven Included</div>
                          <div className="right-col">
                            {product.specification.DutchOvenIncluded}
                          </div>
                        </div>
                      )}

                      {product.specification.NumberofDutchOvensIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Dutch Ovens Included
                          </div>
                          <div className="right-col">
                            {product.specification.NumberofDutchOvensIncluded}
                          </div>
                        </div>
                      )}

                      {product.specification.LidIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">Lid Included</div>
                          <div className="right-col">
                            {product.specification.LidIncluded}
                          </div>
                        </div>
                      )}

                      {product.specification.NumberofLidsIncluded && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Number of Lids Included
                          </div>
                          <div className="right-col">
                            {product.specification.NumberofLidsIncluded}
                          </div>
                        </div>
                      )}

                      {product.specification.LidMaterial && (
                        <div className="item_body_flex">
                          <div className="left-col">Lid Material</div>
                          <div className="right-col">
                            {product.specification.LidMaterial}
                          </div>
                        </div>
                      )}

                      {product.specification.NonStick && (
                        <div className="item_body_flex">
                          <div className="left-col">Non-Stick</div>
                          <div className="right-col">
                            {product.specification.NonStick}
                          </div>
                        </div>
                      )}

                      {product.specification.NonStickLayers && (
                        <div className="item_body_flex">
                          <div className="left-col">Non-Stick Layers</div>
                          <div className="right-col">
                            {product.specification.NonStickLayers}
                          </div>
                        </div>
                      )}

                      {product.specification.Plys && (
                        <div className="item_body_flex">
                          <div className="left-col">Plys</div>
                          <div className="right-col">
                            {product.specification.Plys}
                          </div>
                        </div>
                      )}

                      {product.specification.OvenSafe && (
                        <div className="item_body_flex">
                          <div className="left-col">Oven Safe</div>
                          <div className="right-col">
                            {product.specification.OvenSafe}
                          </div>
                        </div>
                      )}

                      {product.specification.MaximumTemperature && (
                        <div className="item_body_flex">
                          <div className="left-col">Maximum Temperature</div>
                          <div className="right-col">
                            {product.specification.MaximumTemperature}
                          </div>
                        </div>
                      )}

                      {product.specification.ProductCare && (
                        <div className="item_body_flex">
                          <div className="left-col">Product Care</div>
                          <div className="right-col">
                            {product.specification.ProductCare}
                          </div>
                        </div>
                      )}

                      {product.specification.ScratchResistant && (
                        <div className="item_body_flex">
                          <div className="left-col">Scratch Resistant</div>
                          <div className="right-col">
                            {product.specification.ScratchResistant}
                          </div>
                        </div>
                      )}

                      {product.specification.Stackable && (
                        <div className="item_body_flex">
                          <div className="left-col">Stackable</div>
                          <div className="right-col">
                            {product.specification.Stackable}
                          </div>
                        </div>
                      )}

                      {product.specification.TechniqueWeave && (
                        <div className="item_body_flex">
                          <div className="left-col">Technique Weave</div>
                          <div className="right-col">
                            {product.specification.TechniqueWeave}
                          </div>
                        </div>
                      )}

                      {product.specification.Pattern && (
                        <div className="item_body_flex">
                          <div className="left-col">Pattern</div>
                          <div className="right-col">
                            {product.specification.Pattern}
                          </div>
                        </div>
                      )}

                      {product.specification.Location && (
                        <div className="item_body_flex">
                          <div className="left-col">Location</div>
                          <div className="right-col">
                            {product.specification.Location}
                          </div>
                        </div>
                      )}

                      {product.specification.NonSlipBacking && (
                        <div className="item_body_flex">
                          <div className="left-col">Non-Slip Backing</div>
                          <div className="right-col">
                            {product.specification.NonSlipBacking}
                          </div>
                        </div>
                      )}

                      {product.specification.Reversible && (
                        <div className="item_body_flex">
                          <div className="left-col">Reversible</div>
                          <div className="right-col">
                            {product.specification.Reversible}
                          </div>
                        </div>
                      )}

                      {product.specification.BackingMaterial && (
                        <div className="item_body_flex">
                          <div className="left-col">Backing Material</div>
                          <div className="right-col">
                            {product.specification.BackingMaterial}
                          </div>
                        </div>
                      )}

                      {product.specification.Designer && (
                        <div className="item_body_flex">
                          <div className="left-col">Designer</div>
                          <div className="right-col">
                            {product.specification.Designer}
                          </div>
                        </div>
                      )}

                      {product.specification.Color && (
                        <div className="item_body_flex">
                          <div className="left-col">Color</div>
                          <div className="right-col">
                            {product.specification.Color}
                          </div>
                        </div>
                      )}

                      {product.specification.CommercialWarranty && (
                        <div className="item_body_flex">
                          <div className="left-col">Commercial Warranty</div>
                          <div className="right-col">
                            {product.specification.CommercialWarranty}
                          </div>
                        </div>
                      )}

                      {product.specification.ProductWarranty && (
                        <div className="item_body_flex">
                          <div className="left-col">Product Warranty</div>
                          <div className="right-col">
                            {product.specification.ProductWarranty}
                          </div>
                        </div>
                      )}

                      {product.specification.WarrantyLength && (
                        <div className="item_body_flex">
                          <div className="left-col">Warranty Length</div>
                          <div className="right-col">
                            {product.specification.WarrantyLength}
                          </div>
                        </div>
                      )}

                      {product.specification.FullorLimitedWarranty && (
                        <div className="item_body_flex">
                          <div className="left-col">
                            Full or Limited Warranty
                          </div>
                          <div className="right-col">
                            {product.specification.FullorLimitedWarranty}
                          </div>
                        </div>
                      )}

                      {product.specification.WarrantyDetails && (
                        <div className="item_body_flex">
                          <div className="left-col">Warranty Details</div>
                          <div className="right-col">
                            {product.specification.WarrantyDetails}
                          </div>
                        </div>
                      )}
                      {product.specification.SOR2016176Compliant && (
                        <div className="item_body_flex">
                          <div className="left-col">SOR 2016176 Compliant</div>
                          <div className="right-col">
                            {product.specification.SOR2016176Compliant}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProductDetail;
