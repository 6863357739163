import React, { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (productId, quantity, size, color) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) =>
          item.id === productId && item.size === size && item.color === color
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item.id === productId && item.size === size && item.color === color
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        const newItem = {
          id: productId,
          quantity,
          ...(size && { size }),
          ...(color && { color }),
        };
        return [...prevItems, newItem];
      }
    });
  };
  const removeFromCart = (productId, size, color) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => {
        const matchesId = item.id === productId;
        const matchesSize = size ? item.size === size : true;
        const matchesColor = color ? item.color === color : true;
        return !(matchesId && matchesSize && matchesColor);
      })
    );
  };

  const updateQuantity = (id, quantity, size, color) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((item) =>
        item.id === id && item.size === size && item.color === color
          ? { ...item, quantity }
          : item
      )
    );
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        updateQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
