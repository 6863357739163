import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="main1_box">
          <div className="main1_box_inner">
            <video
              className="_1ekqj2r4"
              data-testing-id="media-banner-video"
              crossOrigin="anonymous"
              muted
              data-hb-id="Video"
              preload="metadata"
              playsInline
              autoPlay
              loop
            >
              <source
                src="https://secure.img1-fg.wfcdn.com/dm/video/c6cec29f-fd45-476d-9a90-df37be1745d8/24_bf-phase02-desktop-usca-boxes-edit.mp4"
                type="video/mp4"
                data-hb-id="Video.Source"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="main2_box">
          <div className="main2_box_inner">
            <h2>Keep Shopping For Area Rugs</h2>
            <div className="main2_flex">
              <ul>
                {productData.map((chair, index) => (
                  <li key={index}>
                    <div className="main2_li_inner">
                      <Link to={`/shopDetails/${chair.id}`} onClick={toTop}>
                        <div className="main2_chair_one">
                          <span class="main2_chair_one_span">
                            <span class="_1diqka39">
                              Early Black Friday Deal
                            </span>
                          </span>
                          <div className="main2_chair_one_img">
                            <img
                              alt="Amber Lewis x Loloi Alie Taupe / Dove Area Rug"
                              src={chair.images[0]}
                              sizes="100vw"
                            />
                          </div>
                        </div>
                        <h2>{chair.name}</h2>
                        <div className="main2_chair_two">
                          <span class="main2_chair_two_span">
                            <span class="main2_chair_two_price">
                              ${chair.price}
                            </span>
                            <s class="main2_chair_two_pre_price">
                              ${chair.prePrice}
                            </s>
                          </span>
                        </div>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="main2_deal_img">
              <img
                alt=""
                src="https://assets.wfcdn.com/im/20365701/resize-h208-w2000%5Ecompr-r85/3111/311115309/deals_of_the_day_featured_deal%3A_velvet_task_chair_shop_all_311115309.jpg"
              />
            </div>
            <div className="main2_deal_img">
              <img
                alt=""
                src="https://assets.wfcdn.com/im/36236120/resize-h208-w2000%5Ecompr-r85/3095/309571220/app-only_offer_extra_10%25_off%2A%2A_48_hours_only%3A_use_code_app10_%E2%86%92_%2A%2Aselect_products_only._terms_and_conditions_apply._309571220.jpg"
              />
            </div>
            <div className="main2_deal_img">
              <img
                alt=""
                src="https://assets.wfcdn.com/im/51070141/resize-h454-w2000%5Ecompr-r85/3087/308723295/holiday_essentials_for_less._host%2C_toast%2C_and_save_308723295.jpg"
              />
            </div>

            <div className="main2_holiday_box">
              <h2>holiday magic made affordable</h2>
              <div className="main2_holiday_box_flex">
                <div className="main2_holiday_body">
                  <img
                    alt="cherry red"
                    src="https://assets.wfcdn.com/im/95309503/scale-h780-w640%5Ecompr-r85/3071/307188325/default_name.jpg"
                  ></img>
                </div>
                <div className="main2_holiday_body">
                  <img
                    alt="christmas trees"
                    srcset="https://assets.wfcdn.com/im/36241276/scale-h761-w624%5Ecompr-r85/3071/307185737/default_name.jpg 624w, https://assets.wfcdn.com/im/24738317/scale-h1522-w1248%5Ecompr-r85/3071/307185737/default_name.jpg 1248w, https://assets.wfcdn.com/im/07809380/scale-h273-w224%5Ecompr-r85/3071/307185737/default_name.jpg 224w, https://assets.wfcdn.com/im/98340471/scale-h546-w448%5Ecompr-r85/3071/307185737/default_name.jpg 448w, https://assets.wfcdn.com/im/81425755/scale-h523-w429%5Ecompr-r85/3071/307185737/default_name.jpg 429w, https://assets.wfcdn.com/im/65108390/scale-h1046-w858%5Ecompr-r85/3071/307185737/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/65065927/scale-h780-w640%5Ecompr-r85/3071/307185737/default_name.jpg"
                    sizes="(min-width:1780px) 640px, (min-width:960px) 25vw, (min-width:640px) 25vw, 100vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="ornaments"
                    src="https://assets.wfcdn.com/im/52964739/scale-h780-w640%5Ecompr-r85/3071/307186486/default_name.jpg"
                    class="b32ygh0"
                  />
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="set the fall table"
                    src="https://assets.wfcdn.com/im/91559504/scale-h780-w640%5Ecompr-r85/3026/302636642/default_name.jpg"
                    class="b32ygh0"
                  />
                </div>
              </div>
            </div>

            <div className="main2_deal_img">
              <img
                alt=""
                src="https://assets.wfcdn.com/im/51125420/resize-h454-w2000%5Ecompr-r85/3101/310178923/save_on_christmas_staples._shop_trees_%26_accessories_310178923.jpg"
              />
            </div>

            <div className="main2_holiday_box">
              <h2>everything but the tree</h2>
              <p>Turn up the joy (and style) for the holidays.</p>
              <div className="main2_holiday_box_flex">
                <div className="main2_holiday_body">
                  <img
                    alt="traditional"
                    srcset="https://assets.wfcdn.com/im/89894341/scale-h304-w304%5Ecompr-r85/3098/309881975/default_name.jpg 304w, https://assets.wfcdn.com/im/23323237/scale-h608-w608%5Ecompr-r85/3098/309881975/default_name.jpg 608w, https://assets.wfcdn.com/im/46801061/scale-h224-w224%5Ecompr-r85/3098/309881975/default_name.jpg 224w, https://assets.wfcdn.com/im/57830619/scale-h448-w448%5Ecompr-r85/3098/309881975/default_name.jpg 448w, https://assets.wfcdn.com/im/53943227/scale-h429-w429%5Ecompr-r85/3098/309881975/default_name.jpg 429w, https://assets.wfcdn.com/im/91754683/scale-h858-w858%5Ecompr-r85/3098/309881975/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/40349179/scale-h445-w445%5Ecompr-r85/3098/309881975/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>lean into tradition</h3>
                </div>
                <div className="main2_holiday_body">
                  <img
                    alt="winter whites"
                    srcset="https://assets.wfcdn.com/im/01245254/scale-h304-w304%5Ecompr-r85/3098/309881976/default_name.jpg 304w, https://assets.wfcdn.com/im/34674150/scale-h608-w608%5Ecompr-r85/3098/309881976/default_name.jpg 608w, https://assets.wfcdn.com/im/58151974/scale-h224-w224%5Ecompr-r85/3098/309881976/default_name.jpg 224w, https://assets.wfcdn.com/im/46479706/scale-h448-w448%5Ecompr-r85/3098/309881976/default_name.jpg 448w, https://assets.wfcdn.com/im/42592314/scale-h429-w429%5Ecompr-r85/3098/309881976/default_name.jpg 429w, https://assets.wfcdn.com/im/80403770/scale-h858-w858%5Ecompr-r85/3098/309881976/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/28998266/scale-h445-w445%5Ecompr-r85/3098/309881976/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>warm up with winter whites</h3>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="transitional"
                    srcset="https://assets.wfcdn.com/im/23947080/scale-h304-w304%5Ecompr-r85/3098/309881978/default_name.jpg 304w, https://assets.wfcdn.com/im/37591320/scale-h608-w608%5Ecompr-r85/3098/309881978/default_name.jpg 608w, https://assets.wfcdn.com/im/80853800/scale-h224-w224%5Ecompr-r85/3098/309881978/default_name.jpg 224w, https://assets.wfcdn.com/im/23777880/scale-h448-w448%5Ecompr-r85/3098/309881978/default_name.jpg 448w, https://assets.wfcdn.com/im/80109512/scale-h429-w429%5Ecompr-r85/3098/309881978/default_name.jpg 429w, https://assets.wfcdn.com/im/57701944/scale-h858-w858%5Ecompr-r85/3098/309881978/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/06296440/scale-h445-w445%5Ecompr-r85/3098/309881978/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>go for a transitional twist</h3>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="modern"
                    src="https://assets.wfcdn.com/im/40192737/scale-h445-w445%5Ecompr-r85/3098/309881980/default_name.jpg"
                    class="b32ygh0"
                  />
                  <h3>deck the modern halls</h3>
                </div>
              </div>
            </div>

            <div className="main2_deal_img">
              <video
                data-testing-id="media-banner-video"
                crossOrigin="anonymous"
                muted
                data-hb-id="Video"
                preload="metadata"
                playsInline
                autoPlay
                loop
              >
                <source
                  src="https://secure.img1-fg.wfcdn.com/dm/video/3424eea6-d971-4b66-bf40-ce1963bf5624/1108_nbp_2024_hostingmadeeasy_lhp_desktop.mp4"
                  type="video/mp4"
                  data-hb-id="Video.Source"
                />
              </video>{" "}
            </div>

            <div className="main2_holiday_box">
              <div className="main2_holiday_box_flex">
                <div className="main2_holiday_body">
                  <img
                    alt="knife sets"
                    srcset="https://assets.wfcdn.com/im/83237663/scale-h304-w304%5Ecompr-r85/3047/304798627/default_name.jpg 304w, https://assets.wfcdn.com/im/16666559/scale-h608-w608%5Ecompr-r85/3047/304798627/default_name.jpg 608w, https://assets.wfcdn.com/im/44074047/scale-h464-w464%5Ecompr-r85/3047/304798627/default_name.jpg 464w, https://assets.wfcdn.com/im/63689503/scale-h928-w928%5Ecompr-r85/3047/304798627/default_name.jpg 928w, https://assets.wfcdn.com/im/60599905/scale-h429-w429%5Ecompr-r85/3047/304798627/default_name.jpg 429w, https://assets.wfcdn.com/im/98411361/scale-h858-w858%5Ecompr-r85/3047/304798627/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/42331905/scale-h480-w480%5Ecompr-r85/3047/304798627/default_name.jpg"
                    sizes="(min-width:1780px) 480px, (min-width:960px) 25vw, (min-width:640px) 50vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>knife sets from $30</h3>
                  <p>Precision cutting every time.</p>
                </div>
                <div className="main2_holiday_body">
                  <img
                    alt="dinnerware"
                    srcset="https://assets.wfcdn.com/im/31624299/scale-h304-w304%5Ecompr-r85/3047/304797451/default_name.jpg 304w, https://assets.wfcdn.com/im/01804597/scale-h608-w608%5Ecompr-r85/3047/304797451/default_name.jpg 608w, https://assets.wfcdn.com/im/70787915/scale-h464-w464%5Ecompr-r85/3047/304797451/default_name.jpg 464w, https://assets.wfcdn.com/im/48827541/scale-h928-w928%5Ecompr-r85/3047/304797451/default_name.jpg 928w, https://assets.wfcdn.com/im/75461867/scale-h429-w429%5Ecompr-r85/3047/304797451/default_name.jpg 429w, https://assets.wfcdn.com/im/81693973/scale-h858-w858%5Ecompr-r85/3047/304797451/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/57193867/scale-h480-w480%5Ecompr-r85/3047/304797451/default_name.jpg"
                    sizes="(min-width:1780px) 480px, (min-width:960px) 25vw, (min-width:640px) 50vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>dinnerware from $30</h3>{" "}
                  <p>Set a seasonal tablescape in your just-right style.</p>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="blenders"
                    srcset="https://assets.wfcdn.com/im/61706742/scale-h304-w304%5Ecompr-r85/3047/304799344/default_name.jpg 304w, https://assets.wfcdn.com/im/95135638/scale-h608-w608%5Ecompr-r85/3047/304799344/default_name.jpg 608w, https://assets.wfcdn.com/im/22543126/scale-h464-w464%5Ecompr-r85/3047/304799344/default_name.jpg 464w, https://assets.wfcdn.com/im/42158582/scale-h928-w928%5Ecompr-r85/3047/304799344/default_name.jpg 928w, https://assets.wfcdn.com/im/82130826/scale-h429-w429%5Ecompr-r85/3047/304799344/default_name.jpg 429w, https://assets.wfcdn.com/im/19942282/scale-h858-w858%5Ecompr-r85/3047/304799344/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/63862826/scale-h480-w480%5Ecompr-r85/3047/304799344/default_name.jpg"
                    sizes="(min-width:1780px) 480px, (min-width:960px) 25vw, (min-width:640px) 50vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>blenders from $40</h3> <p>Power through meal prep.</p>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="drinkware"
                    srcset="https://assets.wfcdn.com/im/24120494/scale-h304-w304%5Ecompr-r85/3047/304797470/default_name.jpg 304w, https://assets.wfcdn.com/im/09308402/scale-h608-w608%5Ecompr-r85/3047/304797470/default_name.jpg 608w, https://assets.wfcdn.com/im/63284110/scale-h464-w464%5Ecompr-r85/3047/304797470/default_name.jpg 464w, https://assets.wfcdn.com/im/56331346/scale-h928-w928%5Ecompr-r85/3047/304797470/default_name.jpg 928w, https://assets.wfcdn.com/im/67958062/scale-h429-w429%5Ecompr-r85/3047/304797470/default_name.jpg 429w, https://assets.wfcdn.com/im/89197778/scale-h858-w858%5Ecompr-r85/3047/304797470/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/49690062/scale-h480-w480%5Ecompr-r85/3047/304797470/default_name.jpg"
                    sizes="(min-width:1780px) 480px, (min-width:960px) 25vw, (min-width:640px) 50vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>drinkware under $25</h3>{" "}
                  <p>Make every sip a celebration.</p>
                </div>
              </div>
            </div>

            <div className="main2_deal_img">
              <img
                class="gyz45k1 gyz45k2"
                alt="wayfair rewards"
                src="https://assets.wfcdn.com/im/67843590/resize-h454-w2000%5Ecompr-r85/3085/308539675/wayfair_rewards_308539675.jpg"
                srcset="https://assets.wfcdn.com/im/67843590/resize-h454-w2000%5Ecompr-r85/3085/308539675/wayfair_rewards_308539675.jpg 960w, https://assets.wfcdn.com/im/94307457/resize-h298-w825%5Ecompr-r85/3085/308539673/wayfair_rewards_308539673.jpg 1w"
                sizes=""
              />
            </div>

            <div className="main2_ship_grid">
              <div className="main2_ship_grid_body">
                <img
                  alt="5% back in rewards"
                  srcset="https://assets.wfcdn.com/im/38658150/scale-h657-w624%5Ecompr-r85/3085/308537610/default_name.jpg 624w, https://assets.wfcdn.com/im/52374212/scale-h1314-w1248%5Ecompr-r85/3085/308537610/default_name.jpg 1248w, https://assets.wfcdn.com/im/37554856/scale-h185-w176%5Ecompr-r85/3085/308537610/default_name.jpg 176w, https://assets.wfcdn.com/im/20381066/scale-h370-w352%5Ecompr-r85/3085/308537610/default_name.jpg 352w, https://assets.wfcdn.com/im/62208127/scale-h358-w340%5Ecompr-r85/3085/308537610/default_name.jpg 340w, https://assets.wfcdn.com/im/03936152/scale-h716-w680%5Ecompr-r85/3085/308537610/default_name.jpg 680w"
                  src="https://assets.wfcdn.com/im/69162715/scale-h674-w640%5Ecompr-r85/3085/308537610/default_name.jpg"
                  sizes="(min-width:1780px) 640px, (min-width:960px) 20vw, (min-width:640px) 20vw, 100vw"
                  data-hb-id="FluidImage"
                  class="b32ygh0"
                />
              </div>
              <div className="main2_ship_grid_body">
                <img
                  alt="free shipping on every order"
                  srcset="https://assets.wfcdn.com/im/93410940/scale-h657-w624%5Ecompr-r85/3085/308537609/default_name.jpg 624w, https://assets.wfcdn.com/im/87840294/scale-h1314-w1248%5Ecompr-r85/3085/308537609/default_name.jpg 1248w, https://assets.wfcdn.com/im/92307646/scale-h185-w176%5Ecompr-r85/3085/308537609/default_name.jpg 176w, https://assets.wfcdn.com/im/75133856/scale-h370-w352%5Ecompr-r85/3085/308537609/default_name.jpg 352w, https://assets.wfcdn.com/im/16960917/scale-h358-w340%5Ecompr-r85/3085/308537609/default_name.jpg 340w, https://assets.wfcdn.com/im/58688942/scale-h716-w680%5Ecompr-r85/3085/308537609/default_name.jpg 680w"
                  src="https://assets.wfcdn.com/im/14409925/scale-h674-w640%5Ecompr-r85/3085/308537609/default_name.jpg"
                  sizes="(min-width:1780px) 640px, (min-width:960px) 20vw, (min-width:640px) 20vw, 100vw"
                  data-hb-id="FluidImage"
                  class="b32ygh0"
                />
              </div>
              <div className="main2_ship_grid_body">
                <img
                  alt="special offers &amp; perks"
                  srcset="https://assets.wfcdn.com/im/16112766/scale-h657-w624%5Ecompr-r85/3085/308537607/default_name.jpg 624w, https://assets.wfcdn.com/im/65138468/scale-h1314-w1248%5Ecompr-r85/3085/308537607/default_name.jpg 1248w, https://assets.wfcdn.com/im/15009472/scale-h185-w176%5Ecompr-r85/3085/308537607/default_name.jpg 176w, https://assets.wfcdn.com/im/97835682/scale-h370-w352%5Ecompr-r85/3085/308537607/default_name.jpg 352w, https://assets.wfcdn.com/im/39662743/scale-h358-w340%5Ecompr-r85/3085/308537607/default_name.jpg 340w, https://assets.wfcdn.com/im/81390768/scale-h716-w680%5Ecompr-r85/3085/308537607/default_name.jpg 680w"
                  src="https://assets.wfcdn.com/im/91708099/scale-h674-w640%5Ecompr-r85/3085/308537607/default_name.jpg"
                  sizes="(min-width:1780px) 640px, (min-width:960px) 20vw, (min-width:640px) 20vw, 100vw"
                  data-hb-id="FluidImage"
                  class="b32ygh0"
                />
              </div>
              <div className="main2_ship_grid_body">
                <img
                  alt="member support line"
                  srcset="https://assets.wfcdn.com/im/38814592/scale-h657-w624%5Ecompr-r85/3085/308537605/default_name.jpg 624w, https://assets.wfcdn.com/im/42436642/scale-h1314-w1248%5Ecompr-r85/3085/308537605/default_name.jpg 1248w, https://assets.wfcdn.com/im/37711298/scale-h185-w176%5Ecompr-r85/3085/308537605/default_name.jpg 176w, https://assets.wfcdn.com/im/74429788/scale-h370-w352%5Ecompr-r85/3085/308537605/default_name.jpg 352w, https://assets.wfcdn.com/im/62364569/scale-h358-w340%5Ecompr-r85/3085/308537605/default_name.jpg 340w, https://assets.wfcdn.com/im/04092594/scale-h716-w680%5Ecompr-r85/3085/308537605/default_name.jpg 680w"
                  src="https://assets.wfcdn.com/im/69006273/scale-h674-w640%5Ecompr-r85/3085/308537605/default_name.jpg"
                  sizes="(min-width:1780px) 640px, (min-width:960px) 20vw, (min-width:640px) 20vw, 100vw"
                  data-hb-id="FluidImage"
                  class="b32ygh0"
                />
              </div>
              <div className="main2_ship_grid_body">
                <img
                  alt="save across our family of brands"
                  srcset="https://assets.wfcdn.com/im/72867331/scale-h657-w624%5Ecompr-r85/3085/308537602/default_name.jpg 624w, https://assets.wfcdn.com/im/08383903/scale-h1314-w1248%5Ecompr-r85/3085/308537602/default_name.jpg 1248w, https://assets.wfcdn.com/im/71764037/scale-h185-w176%5Ecompr-r85/3085/308537602/default_name.jpg 176w, https://assets.wfcdn.com/im/40377049/scale-h370-w352%5Ecompr-r85/3085/308537602/default_name.jpg 352w, https://assets.wfcdn.com/im/98549988/scale-h358-w340%5Ecompr-r85/3085/308537602/default_name.jpg 340w, https://assets.wfcdn.com/im/56821963/scale-h716-w680%5Ecompr-r85/3085/308537602/default_name.jpg 680w"
                  src="https://assets.wfcdn.com/im/65046466/scale-h674-w640%5Ecompr-r85/3085/308537602/default_name.jpg"
                  sizes="(min-width:1780px) 640px, (min-width:960px) 20vw, (min-width:640px) 20vw, 100vw"
                  data-hb-id="FluidImage"
                  class="b32ygh0"
                />
              </div>
            </div>

            <div className="main2_deal_img">
              <img
                class="gyz45k1 gyz45k2"
                alt="wayfair rewards"
                src="https://assets.wfcdn.com/im/36055473/resize-h454-w2000%5Ecompr-r85/3099/309929919/__309929919.jpg"
                sizes=""
              />
            </div>

            <div className="main2_deal_img">
              <img
                class="gyz45k1 gyz45k2"
                alt="a budget-friendly bedroom. Get guest-ready"
                src="https://assets.wfcdn.com/im/23454329/resize-h454-w2000%5Ecompr-r85/3026/302641223/a_budget-friendly_bedroom._get_guest-ready_302641223.jpg"
                srcset="https://assets.wfcdn.com/im/23454329/resize-h454-w2000%5Ecompr-r85/3026/302641223/a_budget-friendly_bedroom._get_guest-ready_302641223.jpg 960w, https://assets.wfcdn.com/im/56543417/resize-h350-w825%5Ecompr-r85/3026/302641182/a_budget-friendly_bedroom._get_guest-ready_302641182.jpg 1w"
                sizes=""
              />
            </div>

            <div className="main2_holiday_box">
              <div className="main2_holiday_box_flex">
                <div className="main2_holiday_body">
                  <img
                    alt="bed"
                    srcset="https://assets.wfcdn.com/im/92426883/scale-h304-w304%5Ecompr-r85/3023/302395824/default_name.jpg 304w, https://assets.wfcdn.com/im/25855779/scale-h608-w608%5Ecompr-r85/3023/302395824/default_name.jpg 608w, https://assets.wfcdn.com/im/49333603/scale-h224-w224%5Ecompr-r85/3023/302395824/default_name.jpg 224w, https://assets.wfcdn.com/im/39669219/scale-h448-w448%5Ecompr-r85/3023/302395824/default_name.jpg 448w, https://assets.wfcdn.com/im/51410685/scale-h429-w429%5Ecompr-r85/3023/302395824/default_name.jpg 429w, https://assets.wfcdn.com/im/89222141/scale-h858-w858%5Ecompr-r85/3023/302395824/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/37816637/scale-h445-w445%5Ecompr-r85/3023/302395824/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>beds under $700</h3>
                  <p>Snuggle-worthy picks for every style.</p>
                </div>
                <div className="main2_holiday_body">
                  <img
                    alt="sheets"
                    srcset="https://assets.wfcdn.com/im/55464692/scale-h304-w304%5Ecompr-r85/3024/302401573/default_name.jpg 304w, https://assets.wfcdn.com/im/88893588/scale-h608-w608%5Ecompr-r85/3024/302401573/default_name.jpg 608w, https://assets.wfcdn.com/im/12371412/scale-h224-w224%5Ecompr-r85/3024/302401573/default_name.jpg 224w, https://assets.wfcdn.com/im/92260268/scale-h448-w448%5Ecompr-r85/3024/302401573/default_name.jpg 448w, https://assets.wfcdn.com/im/88372876/scale-h429-w429%5Ecompr-r85/3024/302401573/default_name.jpg 429w, https://assets.wfcdn.com/im/26184332/scale-h858-w858%5Ecompr-r85/3024/302401573/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/74778828/scale-h445-w445%5Ecompr-r85/3024/302401573/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>sheet sets under $30</h3>{" "}
                  <p>Starters for your sleep sanctuary.</p>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="mattress"
                    srcset="https://assets.wfcdn.com/im/71772418/scale-h304-w304%5Ecompr-r85/3024/302400769/default_name.jpg 304w, https://assets.wfcdn.com/im/38343522/scale-h608-w608%5Ecompr-r85/3024/302400769/default_name.jpg 608w, https://assets.wfcdn.com/im/14865698/scale-h224-w224%5Ecompr-r85/3024/302400769/default_name.jpg 224w, https://assets.wfcdn.com/im/24530082/scale-h448-w448%5Ecompr-r85/3024/302400769/default_name.jpg 448w, https://assets.wfcdn.com/im/79357310/scale-h429-w429%5Ecompr-r85/3024/302400769/default_name.jpg 429w, https://assets.wfcdn.com/im/41545854/scale-h858-w858%5Ecompr-r85/3024/302400769/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/92951358/scale-h445-w445%5Ecompr-r85/3024/302400769/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>mattresses under $500</h3>{" "}
                  <p>Say goodbye to tossing and turning.</p>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="nightstand"
                    srcset="https://assets.wfcdn.com/im/49332094/scale-h304-w304%5Ecompr-r85/3023/302399548/default_name.jpg 304w, https://assets.wfcdn.com/im/12206306/scale-h608-w608%5Ecompr-r85/3023/302399548/default_name.jpg 608w, https://assets.wfcdn.com/im/06238814/scale-h224-w224%5Ecompr-r85/3023/302399548/default_name.jpg 224w, https://assets.wfcdn.com/im/98392866/scale-h448-w448%5Ecompr-r85/3023/302399548/default_name.jpg 448w, https://assets.wfcdn.com/im/05494526/scale-h429-w429%5Ecompr-r85/3023/302399548/default_name.jpg 429w, https://assets.wfcdn.com/im/32316930/scale-h858-w858%5Ecompr-r85/3023/302399548/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/80911426/scale-h445-w445%5Ecompr-r85/3023/302399548/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>nighstands from $80</h3>{" "}
                  <p>The perfect perch for your book (and phone).</p>
                </div>
              </div>
            </div>

            <div className="main2_deal_img">
              <img
                class="gyz45k1 gyz45k2"
                alt="15% off [Re/Fine Logo] Shop now Select items only."
                src="https://assets.wfcdn.com/im/22182282/resize-h208-w2000%5Ecompr-r85/3090/309099344/15%25_off_%5Bre%2Ffine_logo%5D_shop_now_select_items_only._309099344.jpg"
                srcset="https://assets.wfcdn.com/im/22182282/resize-h208-w2000%5Ecompr-r85/3090/309099344/15%25_off_%5Bre%2Ffine_logo%5D_shop_now_select_items_only._309099344.jpg 960w, https://assets.wfcdn.com/im/64561583/resize-h300-w825%5Ecompr-r85/3090/309099343/15%25_off_%5Bre%2Ffine_logo%5D_shop_now_select_items_only._309099343.jpg 1w"
                sizes=""
              />
            </div>

            <div className="main2_deal_img">
              <img
                class="gyz45k1 gyz45k2"
                alt="living room savings. Sit back and celebrate"
                src="https://assets.wfcdn.com/im/96688595/resize-h454-w2000%5Ecompr-r85/3047/304791982/living_room_savings._sit_back_and_celebrate_304791982.jpg"
                srcset="https://assets.wfcdn.com/im/96688595/resize-h454-w2000%5Ecompr-r85/3047/304791982/living_room_savings._sit_back_and_celebrate_304791982.jpg 960w, https://assets.wfcdn.com/im/55352340/resize-h350-w825%5Ecompr-r85/3047/304791987/living_room_savings._sit_back_and_celebrate_304791987.jpg 1w"
                sizes=""
              />
            </div>

            <div className="main2_holiday_box">
              <div className="main2_holiday_box_flex">
                <div className="main2_holiday_body">
                  <img
                    alt="rugs"
                    srcset="https://assets.wfcdn.com/im/45092543/scale-h304-w304%5Ecompr-r85/3047/304788639/default_name.jpg 304w, https://assets.wfcdn.com/im/78521439/scale-h608-w608%5Ecompr-r85/3047/304788639/default_name.jpg 608w, https://assets.wfcdn.com/im/01999263/scale-h224-w224%5Ecompr-r85/3047/304788639/default_name.jpg 224w, https://assets.wfcdn.com/im/02632417/scale-h448-w448%5Ecompr-r85/3047/304788639/default_name.jpg 448w, https://assets.wfcdn.com/im/98745025/scale-h429-w429%5Ecompr-r85/3047/304788639/default_name.jpg 429w, https://assets.wfcdn.com/im/36556481/scale-h858-w858%5Ecompr-r85/3047/304788639/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/85150977/scale-h445-w445%5Ecompr-r85/3047/304788639/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>rugs from $100</h3>
                  <p>Unroll your fave styles.</p>
                </div>
                <div className="main2_holiday_body">
                  <img
                    alt="accent chair"
                    srcset="https://assets.wfcdn.com/im/15310719/scale-h304-w304%5Ecompr-r85/3047/304782358/default_name.jpg 304w, https://assets.wfcdn.com/im/46227681/scale-h608-w608%5Ecompr-r85/3047/304782358/default_name.jpg 608w, https://assets.wfcdn.com/im/72217439/scale-h224-w224%5Ecompr-r85/3047/304782358/default_name.jpg 224w, https://assets.wfcdn.com/im/32414241/scale-h448-w448%5Ecompr-r85/3047/304782358/default_name.jpg 448w, https://assets.wfcdn.com/im/71473151/scale-h429-w429%5Ecompr-r85/3047/304782358/default_name.jpg 429w, https://assets.wfcdn.com/im/66338305/scale-h858-w858%5Ecompr-r85/3047/304782358/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/14932801/scale-h445-w445%5Ecompr-r85/3047/304782358/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>accent chairs from $125</h3>{" "}
                  <p>Stylish seating – all season long.</p>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="floor lamps"
                    srcset="https://assets.wfcdn.com/im/57857801/scale-h304-w304%5Ecompr-r85/3047/304783362/default_name.jpg 304w, https://assets.wfcdn.com/im/24428905/scale-h608-w608%5Ecompr-r85/3047/304783362/default_name.jpg 608w, https://assets.wfcdn.com/im/00951081/scale-h224-w224%5Ecompr-r85/3047/304783362/default_name.jpg 224w, https://assets.wfcdn.com/im/10615465/scale-h448-w448%5Ecompr-r85/3047/304783362/default_name.jpg 448w, https://assets.wfcdn.com/im/93271927/scale-h429-w429%5Ecompr-r85/3047/304783362/default_name.jpg 429w, https://assets.wfcdn.com/im/55460471/scale-h858-w858%5Ecompr-r85/3047/304783362/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/06865975/scale-h445-w445%5Ecompr-r85/3047/304783362/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>floor lamps from $40</h3>{" "}
                  <p>Brighten up winter nights.</p>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="console table"
                    srcset="https://assets.wfcdn.com/im/86026400/scale-h304-w304%5Ecompr-r85/3047/304782700/default_name.jpg 304w, https://assets.wfcdn.com/im/52597504/scale-h608-w608%5Ecompr-r85/3047/304782700/default_name.jpg 608w, https://assets.wfcdn.com/im/29119680/scale-h224-w224%5Ecompr-r85/3047/304782700/default_name.jpg 224w, https://assets.wfcdn.com/im/38784064/scale-h448-w448%5Ecompr-r85/3047/304782700/default_name.jpg 448w, https://assets.wfcdn.com/im/65103328/scale-h429-w429%5Ecompr-r85/3047/304782700/default_name.jpg 429w, https://assets.wfcdn.com/im/27291872/scale-h858-w858%5Ecompr-r85/3047/304782700/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/78697376/scale-h445-w445%5Ecompr-r85/3047/304782700/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>console tables from $50</h3>{" "}
                  <p>Store, stash, and show off your decor.</p>
                </div>
              </div>
            </div>

            <div className="main2_holiday_box">
              <h2>make it multifunctional</h2>
              <p>Holiday hosting? You'll need these.</p>
              <div className="main2_holiday_box_flex">
                <div className="main2_holiday_body">
                  <img
                    alt="Futons"
                    srcset="https://assets.wfcdn.com/im/65222494/scale-h304-w304%5Ecompr-r85/3073/307368347/default_name.jpg 304w, https://assets.wfcdn.com/im/96315906/scale-h608-w608%5Ecompr-r85/3073/307368347/default_name.jpg 608w, https://assets.wfcdn.com/im/22129214/scale-h224-w224%5Ecompr-r85/3073/307368347/default_name.jpg 224w, https://assets.wfcdn.com/im/82502466/scale-h448-w448%5Ecompr-r85/3073/307368347/default_name.jpg 448w, https://assets.wfcdn.com/im/21384926/scale-h429-w429%5Ecompr-r85/3073/307368347/default_name.jpg 429w, https://assets.wfcdn.com/im/16426530/scale-h858-w858%5Ecompr-r85/3073/307368347/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/65021026/scale-h445-w445%5Ecompr-r85/3073/307368347/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>sleeper sofas & futons</h3>
                </div>
                <div className="main2_holiday_body">
                  <img
                    alt="Sleeper chair"
                    srcset="https://assets.wfcdn.com/im/52290186/scale-h304-w304%5Ecompr-r85/3073/307368350/default_name.jpg 304w, https://assets.wfcdn.com/im/18861290/scale-h608-w608%5Ecompr-r85/3073/307368350/default_name.jpg 608w, https://assets.wfcdn.com/im/95383466/scale-h224-w224%5Ecompr-r85/3073/307368350/default_name.jpg 224w, https://assets.wfcdn.com/im/05047850/scale-h448-w448%5Ecompr-r85/3073/307368350/default_name.jpg 448w, https://assets.wfcdn.com/im/98839542/scale-h429-w429%5Ecompr-r85/3073/307368350/default_name.jpg 429w, https://assets.wfcdn.com/im/61028086/scale-h858-w858%5Ecompr-r85/3073/307368350/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/12433590/scale-h445-w445%5Ecompr-r85/3073/307368350/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>sleeper chairs</h3>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="Storage sofas"
                    srcset="https://assets.wfcdn.com/im/72833795/scale-h304-w304%5Ecompr-r85/3073/307368357/default_name.jpg 304w, https://assets.wfcdn.com/im/60595101/scale-h608-w608%5Ecompr-r85/3073/307368357/default_name.jpg 608w, https://assets.wfcdn.com/im/15927075/scale-h224-w224%5Ecompr-r85/3073/307368357/default_name.jpg 224w, https://assets.wfcdn.com/im/74408541/scale-h448-w448%5Ecompr-r85/3073/307368357/default_name.jpg 448w, https://assets.wfcdn.com/im/16671363/scale-h429-w429%5Ecompr-r85/3073/307368357/default_name.jpg 429w, https://assets.wfcdn.com/im/40484477/scale-h858-w858%5Ecompr-r85/3073/307368357/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/91889981/scale-h445-w445%5Ecompr-r85/3073/307368357/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>storage sofas & sectionals</h3>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="Storage ottomans"
                    srcset="https://assets.wfcdn.com/im/08467929/scale-h304-w304%5Ecompr-r85/3073/307368342/default_name.jpg 304w, https://assets.wfcdn.com/im/41896825/scale-h608-w608%5Ecompr-r85/3073/307368342/default_name.jpg 608w, https://assets.wfcdn.com/im/65374649/scale-h224-w224%5Ecompr-r85/3073/307368342/default_name.jpg 224w, https://assets.wfcdn.com/im/55710265/scale-h448-w448%5Ecompr-r85/3073/307368342/default_name.jpg 448w, https://assets.wfcdn.com/im/35369639/scale-h429-w429%5Ecompr-r85/3073/307368342/default_name.jpg 429w, https://assets.wfcdn.com/im/73181095/scale-h858-w858%5Ecompr-r85/3073/307368342/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/21775591/scale-h445-w445%5Ecompr-r85/3073/307368342/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>storage ottomans</h3>
                </div>
              </div>
            </div>

            <div className="main2_deal_img">
              <video
                data-testing-id="media-banner-video"
                crossOrigin="anonymous"
                muted
                data-hb-id="Video"
                preload="metadata"
                playsInline
                autoPlay
                loop
              >
                <source
                  src="https://secure.img1-fg.wfcdn.com/dm/video/fcf98e68-5c2d-4a22-9ff3-45ba76d840d0/wfus_110824_ntb_lhpbanner_desktop.mp4"
                  type="video/mp4"
                  data-hb-id="Video.Source"
                />
              </video>
            </div>

            <div className="main2_holiday_box">
              <div className="main2_holiday_box_flex">
                <div className="main2_holiday_body">
                  <img
                    alt="Cuisinart"
                    srcset="https://assets.wfcdn.com/im/26463806/scale-h304-w304%5Ecompr-r85/3095/309577943/default_name.jpg 304w, https://assets.wfcdn.com/im/06965090/scale-h608-w608%5Ecompr-r85/3095/309577943/default_name.jpg 608w, https://assets.wfcdn.com/im/69557086/scale-h224-w224%5Ecompr-r85/3095/309577943/default_name.jpg 224w, https://assets.wfcdn.com/im/20778530/scale-h448-w448%5Ecompr-r85/3095/309577943/default_name.jpg 448w, https://assets.wfcdn.com/im/70301374/scale-h429-w429%5Ecompr-r85/3095/309577943/default_name.jpg 429w, https://assets.wfcdn.com/im/86854466/scale-h858-w858%5Ecompr-r85/3095/309577943/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/38259970/scale-h445-w445%5Ecompr-r85/3095/309577943/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>Cuisinart</h3>
                  <p>Chef-worthy cookware.</p>
                </div>
                <div className="main2_holiday_body">
                  <img
                    alt="Haden"
                    srcset="https://assets.wfcdn.com/im/15112893/scale-h304-w304%5Ecompr-r85/3095/309577944/default_name.jpg 304w, https://assets.wfcdn.com/im/18316003/scale-h608-w608%5Ecompr-r85/3095/309577944/default_name.jpg 608w, https://assets.wfcdn.com/im/58206173/scale-h224-w224%5Ecompr-r85/3095/309577944/default_name.jpg 224w, https://assets.wfcdn.com/im/32129443/scale-h448-w448%5Ecompr-r85/3095/309577944/default_name.jpg 448w, https://assets.wfcdn.com/im/58950461/scale-h429-w429%5Ecompr-r85/3095/309577944/default_name.jpg 429w, https://assets.wfcdn.com/im/96761917/scale-h858-w858%5Ecompr-r85/3095/309577944/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/49610883/scale-h445-w445%5Ecompr-r85/3095/309577944/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>Haden</h3> <p>Appliances with personality.</p>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="Riedel"
                    srcset="https://assets.wfcdn.com/im/49165632/scale-h304-w304%5Ecompr-r85/3095/309577941/default_name.jpg 304w, https://assets.wfcdn.com/im/84263264/scale-h608-w608%5Ecompr-r85/3095/309577941/default_name.jpg 608w, https://assets.wfcdn.com/im/92258912/scale-h224-w224%5Ecompr-r85/3095/309577941/default_name.jpg 224w, https://assets.wfcdn.com/im/98076704/scale-h448-w448%5Ecompr-r85/3095/309577941/default_name.jpg 448w, https://assets.wfcdn.com/im/93003200/scale-h429-w429%5Ecompr-r85/3095/309577941/default_name.jpg 429w, https://assets.wfcdn.com/im/64152640/scale-h858-w858%5Ecompr-r85/3095/309577941/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/15558144/scale-h445-w445%5Ecompr-r85/3095/309577941/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>Riedel</h3> <p>Serve sips in style.</p>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="Vitamix"
                    srcset="https://assets.wfcdn.com/im/15269335/scale-h304-w304%5Ecompr-r85/3095/309577939/default_name.jpg 304w, https://assets.wfcdn.com/im/81840439/scale-h608-w608%5Ecompr-r85/3095/309577939/default_name.jpg 608w, https://assets.wfcdn.com/im/58362615/scale-h224-w224%5Ecompr-r85/3095/309577939/default_name.jpg 224w, https://assets.wfcdn.com/im/31973001/scale-h448-w448%5Ecompr-r85/3095/309577939/default_name.jpg 448w, https://assets.wfcdn.com/im/35860393/scale-h429-w429%5Ecompr-r85/3095/309577939/default_name.jpg 429w, https://assets.wfcdn.com/im/98048937/scale-h858-w858%5Ecompr-r85/3095/309577939/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/49454441/scale-h445-w445%5Ecompr-r85/3095/309577939/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>Vitamix</h3> <p>Healthy meals made easy.</p>
                </div>
              </div>
            </div>

            <div className="main2_holiday_box">
              <h2>gifts for all (& in your budget)</h2>
              <div className="main2_holiday_box_flex">
                <div className="main2_holiday_body">
                  <img
                    alt="gifts under $100"
                    srcset="https://assets.wfcdn.com/im/42230081/scale-h428-w304%5Ecompr-r85/3101/310174467/default_name.jpg 304w, https://assets.wfcdn.com/im/26604663/scale-h856-w608%5Ecompr-r85/3101/310174467/default_name.jpg 608w, https://assets.wfcdn.com/im/77005375/scale-h315-w224%5Ecompr-r85/3101/310174467/default_name.jpg 224w, https://assets.wfcdn.com/im/65075575/scale-h630-w448%5Ecompr-r85/3101/310174467/default_name.jpg 448w, https://assets.wfcdn.com/im/49911173/scale-h604-w429%5Ecompr-r85/3101/310174467/default_name.jpg 429w, https://assets.wfcdn.com/im/03299764/scale-h1208-w858%5Ecompr-r85/3101/310174467/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/56598590/scale-h627-w445%5Ecompr-r85/3101/310174467/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>gifts under $100</h3>
                  <p>Up the fun factor without overspending.</p>
                </div>
                <div className="main2_holiday_body">
                  <img
                    alt="gifts under $50"
                    srcset="https://assets.wfcdn.com/im/30879168/scale-h428-w304%5Ecompr-r85/3101/310174468/default_name.jpg 304w, https://assets.wfcdn.com/im/37955576/scale-h856-w608%5Ecompr-r85/3101/310174468/default_name.jpg 608w, https://assets.wfcdn.com/im/88356288/scale-h315-w224%5Ecompr-r85/3101/310174468/default_name.jpg 224w, https://assets.wfcdn.com/im/76426488/scale-h630-w448%5Ecompr-r85/3101/310174468/default_name.jpg 448w, https://assets.wfcdn.com/im/38560260/scale-h604-w429%5Ecompr-r85/3101/310174468/default_name.jpg 429w, https://assets.wfcdn.com/im/14650677/scale-h1208-w858%5Ecompr-r85/3101/310174468/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/45247677/scale-h627-w445%5Ecompr-r85/3101/310174468/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>gifts under $50</h3>{" "}
                  <p>Cheers to (affordable) adulting wins.</p>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="for pets"
                    srcset="https://assets.wfcdn.com/im/36498114/scale-h428-w304%5Ecompr-r85/3069/306923607/default_name.jpg 304w, https://assets.wfcdn.com/im/89634438/scale-h856-w608%5Ecompr-r85/3069/306923607/default_name.jpg 608w, https://assets.wfcdn.com/im/55733570/scale-h315-w224%5Ecompr-r85/3069/306923607/default_name.jpg 224w, https://assets.wfcdn.com/im/51163526/scale-h630-w448%5Ecompr-r85/3069/306923607/default_name.jpg 448w, https://assets.wfcdn.com/im/28817022/scale-h604-w429%5Ecompr-r85/3069/306923607/default_name.jpg 429w, https://assets.wfcdn.com/im/12939337/scale-h1208-w858%5Ecompr-r85/3069/306923607/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/22129605/scale-h627-w445%5Ecompr-r85/3069/306923607/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>for pets (& their people)</h3>{" "}
                  <p>Make tails wag and hearts melt.</p>
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="for the littles"
                    srcset="https://assets.wfcdn.com/im/52520187/scale-h428-w304%5Ecompr-r85/3069/306924359/default_name.jpg 304w, https://assets.wfcdn.com/im/21354931/scale-h856-w608%5Ecompr-r85/3069/306924359/default_name.jpg 608w, https://assets.wfcdn.com/im/71755643/scale-h315-w224%5Ecompr-r85/3069/306924359/default_name.jpg 224w, https://assets.wfcdn.com/im/35141453/scale-h630-w448%5Ecompr-r85/3069/306924359/default_name.jpg 448w, https://assets.wfcdn.com/im/55160905/scale-h604-w429%5Ecompr-r85/3069/306924359/default_name.jpg 429w, https://assets.wfcdn.com/im/96917264/scale-h1208-w858%5Ecompr-r85/3069/306924359/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/61848322/scale-h627-w445%5Ecompr-r85/3069/306924359/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>for the littles</h3>{" "}
                  <p>Inspire imagination with playful picks.</p>
                </div>
              </div>
            </div>

            <div className="main2_holiday_box">
              <h2>explore our exclusive brands</h2>
              <p>
                Bring your vision to life with hand-curated collections, priced
                just right.
              </p>
              <div className="main2_holiday_box_flex">
                <div className="main2_holiday_body">
                  <img
                    alt="Mercury Row"
                    srcset="https://assets.wfcdn.com/im/12690252/scale-h371-w304%5Ecompr-r85/3026/302643847/default_name.jpg 304w, https://assets.wfcdn.com/im/40960459/scale-h742-w608%5Ecompr-r85/3026/302643847/default_name.jpg 608w, https://assets.wfcdn.com/im/90569896/scale-h273-w224%5Ecompr-r85/3026/302643847/default_name.jpg 224w, https://assets.wfcdn.com/im/98247549/scale-h546-w448%5Ecompr-r85/3026/302643847/default_name.jpg 448w, https://assets.wfcdn.com/im/15162265/scale-h523-w429%5Ecompr-r85/3026/302643847/default_name.jpg 429w, https://assets.wfcdn.com/im/31479630/scale-h1046-w858%5Ecompr-r85/3026/302643847/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/79238307/scale-h543-w445%5Ecompr-r85/3026/302643847/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                </div>
                <div className="main2_holiday_body">
                  <img
                    alt="Three Posts"
                    srcset="https://assets.wfcdn.com/im/10168016/scale-h371-w304%5Ecompr-r85/3026/302643854/default_name.jpg 304w, https://assets.wfcdn.com/im/81897809/scale-h742-w608%5Ecompr-r85/3026/302643854/default_name.jpg 608w, https://assets.wfcdn.com/im/32288372/scale-h273-w224%5Ecompr-r85/3026/302643854/default_name.jpg 224w, https://assets.wfcdn.com/im/73861479/scale-h546-w448%5Ecompr-r85/3026/302643854/default_name.jpg 448w, https://assets.wfcdn.com/im/56946763/scale-h523-w429%5Ecompr-r85/3026/302643854/default_name.jpg 429w, https://assets.wfcdn.com/im/40629398/scale-h1046-w858%5Ecompr-r85/3026/302643854/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/56380039/scale-h543-w445%5Ecompr-r85/3026/302643854/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="Sand &amp; Stable"
                    srcset="https://assets.wfcdn.com/im/10011574/scale-h371-w304%5Ecompr-r85/3026/302643849/default_name.jpg 304w, https://assets.wfcdn.com/im/18258633/scale-h742-w608%5Ecompr-r85/3026/302643849/default_name.jpg 608w, https://assets.wfcdn.com/im/67868070/scale-h273-w224%5Ecompr-r85/3026/302643849/default_name.jpg 224w, https://assets.wfcdn.com/im/20949375/scale-h546-w448%5Ecompr-r85/3026/302643849/default_name.jpg 448w, https://assets.wfcdn.com/im/37864091/scale-h523-w429%5Ecompr-r85/3026/302643849/default_name.jpg 429w, https://assets.wfcdn.com/im/54181456/scale-h1046-w858%5Ecompr-r85/3026/302643849/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/56536481/scale-h543-w445%5Ecompr-r85/3026/302643849/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                </div>{" "}
                <div className="main2_holiday_body">
                  <img
                    alt="Kelly Clarkson Home"
                    srcset="https://assets.wfcdn.com/im/58093904/scale-h371-w304%5Ecompr-r85/3026/302643843/default_name.jpg 304w, https://assets.wfcdn.com/im/86364111/scale-h742-w608%5Ecompr-r85/3026/302643843/default_name.jpg 608w, https://assets.wfcdn.com/im/35973548/scale-h273-w224%5Ecompr-r85/3026/302643843/default_name.jpg 224w, https://assets.wfcdn.com/im/52843897/scale-h546-w448%5Ecompr-r85/3026/302643843/default_name.jpg 448w, https://assets.wfcdn.com/im/69758613/scale-h523-w429%5Ecompr-r85/3026/302643843/default_name.jpg 429w, https://assets.wfcdn.com/im/86075978/scale-h1046-w858%5Ecompr-r85/3026/302643843/default_name.jpg 858w"
                    src="https://assets.wfcdn.com/im/70325337/scale-h543-w445%5Ecompr-r85/3026/302643843/default_name.jpg"
                    sizes="(min-width:1780px) 445px, (min-width:960px) 25vw, (min-width:640px) 25vw, 50vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                </div>
              </div>
            </div>

            <div className="main2_deal_img">
              <img
                class="gyz45k1 gyz45k2"
                alt="Financing Available. Find the payment option that's right for you. Learn more. "
                src="https://assets.wfcdn.com/im/22247260/resize-h454-w2000%5Ecompr-r85/2897/289751200/financing_available._find_the_payment_option_that%27s_right_for_you._learn_more.__289751200.jpg"
                srcset="https://assets.wfcdn.com/im/22247260/resize-h454-w2000%5Ecompr-r85/2897/289751200/financing_available._find_the_payment_option_that%27s_right_for_you._learn_more.__289751200.jpg 960w, https://assets.wfcdn.com/im/26314657/resize-h350-w825%5Ecompr-r85/2897/289751201/financing_available._find_the_payment_option_that%27s_right_for_you._learn_more._289751201.jpg 1w"
                sizes=""
              />
            </div>
            <div className="main2_holiday_box">
              <h2>what we're doing to make an impact</h2>
              <div className="main2_holiday_box_flex main2_holiday_box_doing">
                <div className="main2_holiday_body  main2_holiday_box_doing_body">
                  <img
                    alt="Our Commitments"
                    srcset="https://assets.wfcdn.com/im/83808757/scale-h362-w624%5Ecompr-r85/2352/235224551/default_name.jpg 624w, https://assets.wfcdn.com/im/99578228/scale-h724-w1248%5Ecompr-r85/2352/235224551/default_name.jpg 1248w, https://assets.wfcdn.com/im/88006319/scale-h175-w301%5Ecompr-r85/2352/235224551/default_name.jpg 301w, https://assets.wfcdn.com/im/45787222/scale-h350-w602%5Ecompr-r85/2352/235224551/default_name.jpg 602w, https://assets.wfcdn.com/im/64020343/scale-h332-w571%5Ecompr-r85/2352/235224551/default_name.jpg 571w, https://assets.wfcdn.com/im/16012632/scale-h664-w1142%5Ecompr-r85/2352/235224551/default_name.jpg 1142w"
                    src="https://assets.wfcdn.com/im/00105264/scale-h372-w640%5Ecompr-r85/2352/235224551/default_name.jpg"
                    sizes="(min-width:1780px) 640px, (min-width:960px) 33vw, (min-width:640px) 33vw, 100vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>contributing to all the homes we share</h3>
                  <p>
                    Learn more about our DEI, Social Impact, and Sustainability
                    Work →
                  </p>
                </div>
                <div className="main2_holiday_body main2_holiday_box_doing_body">
                  <img
                    alt="Shop Black-Owned Brands"
                    srcset="https://assets.wfcdn.com/im/61106931/scale-h362-w624%5Ecompr-r85/2352/235224553/default_name.jpg 624w, https://assets.wfcdn.com/im/76876402/scale-h724-w1248%5Ecompr-r85/2352/235224553/default_name.jpg 1248w, https://assets.wfcdn.com/im/10708145/scale-h175-w301%5Ecompr-r85/2352/235224553/default_name.jpg 301w, https://assets.wfcdn.com/im/76914604/scale-h350-w602%5Ecompr-r85/2352/235224553/default_name.jpg 602w, https://assets.wfcdn.com/im/86722169/scale-h332-w571%5Ecompr-r85/2352/235224553/default_name.jpg 571w, https://assets.wfcdn.com/im/38714458/scale-h664-w1142%5Ecompr-r85/2352/235224553/default_name.jpg 1142w"
                    src="https://assets.wfcdn.com/im/22807090/scale-h372-w640%5Ecompr-r85/2352/235224553/default_name.jpg"
                    sizes="(min-width:1780px) 640px, (min-width:960px) 33vw, (min-width:640px) 33vw, 100vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>building supplier diversity</h3>{" "}
                  <p>Learn more about the makers behind the businesses →</p>
                </div>{" "}
                <div className="main2_holiday_body main2_holiday_box_doing_body">
                  <img
                    alt="Shop Sustainably "
                    srcset="https://assets.wfcdn.com/im/38405105/scale-h362-w624%5Ecompr-r85/2352/235224555/default_name.jpg 624w, https://assets.wfcdn.com/im/54174576/scale-h724-w1248%5Ecompr-r85/2352/235224555/default_name.jpg 1248w, https://assets.wfcdn.com/im/33409971/scale-h175-w301%5Ecompr-r85/2352/235224555/default_name.jpg 301w, https://assets.wfcdn.com/im/99616430/scale-h350-w602%5Ecompr-r85/2352/235224555/default_name.jpg 602w, https://assets.wfcdn.com/im/09423995/scale-h332-w571%5Ecompr-r85/2352/235224555/default_name.jpg 571w, https://assets.wfcdn.com/im/61416284/scale-h664-w1142%5Ecompr-r85/2352/235224555/default_name.jpg 1142w"
                    src="https://assets.wfcdn.com/im/45508916/scale-h372-w640%5Ecompr-r85/2352/235224555/default_name.jpg"
                    sizes="(min-width:1780px) 640px, (min-width:960px) 33vw, (min-width:640px) 33vw, 100vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <h3>all things home, sustainably</h3>{" "}
                  <p>
                    Discover so many (easy!) ways to live more sustainably →
                  </p>
                </div>{" "}
              </div>
            </div>

            <div className="main2_shop_box">
              <h2>shop by department</h2>
              <div className="main2_shop_box_inner">
                <div className="main2_shop_body">
                  <img
                    alt="Cabinetry"
                    srcset="https://assets.wfcdn.com/im/99865991/scale-h195-w195%5Ecompr-r85/2320/232096871/default_name.jpg 195w, https://assets.wfcdn.com/im/09005479/scale-h390-w390%5Ecompr-r85/2320/232096871/default_name.jpg 390w, https://assets.wfcdn.com/im/4021401/scale-h176-w176%5Ecompr-r85/2320/232096871/default_name.jpg 176w, https://assets.wfcdn.com/im/96197991/scale-h352-w352%5Ecompr-r85/2320/232096871/default_name.jpg 352w, https://assets.wfcdn.com/im/87323303/scale-h233-w233%5Ecompr-r85/2320/232096871/default_name.jpg 233w, https://assets.wfcdn.com/im/18132505/scale-h466-w466%5Ecompr-r85/2320/232096871/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/46326471/scale-h249-w249%5Ecompr-r85/2320/232096871/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  ></img>
                  <p>Custom Cabinetry</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Pet"
                    srcset="https://assets.wfcdn.com/im/79768518/scale-h195-w195%5Ecompr-r85/1321/132124505/default_name.jpg 195w, https://assets.wfcdn.com/im/88908006/scale-h390-w390%5Ecompr-r85/1321/132124505/default_name.jpg 390w, https://assets.wfcdn.com/im/75881126/scale-h176-w176%5Ecompr-r85/1321/132124505/default_name.jpg 176w, https://assets.wfcdn.com/im/76100518/scale-h352-w352%5Ecompr-r85/1321/132124505/default_name.jpg 352w, https://assets.wfcdn.com/im/27741466/scale-h233-w233%5Ecompr-r85/1321/132124505/default_name.jpg 233w, https://assets.wfcdn.com/im/38229978/scale-h466-w466%5Ecompr-r85/1321/132124505/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/68738298/scale-h249-w249%5Ecompr-r85/1321/132124505/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p>Pet</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Baby &amp; Kids"
                    srcset="https://assets.wfcdn.com/im/35555458/scale-h195-w195%5Ecompr-r85/9847/98477084/default_name.jpg 195w, https://assets.wfcdn.com/im/31650402/scale-h390-w390%5Ecompr-r85/9847/98477084/default_name.jpg 390w, https://assets.wfcdn.com/im/04612002/scale-h176-w176%5Ecompr-r85/9847/98477084/default_name.jpg 176w, https://assets.wfcdn.com/im/25203806/scale-h352-w352%5Ecompr-r85/9847/98477084/default_name.jpg 352w, https://assets.wfcdn.com/im/19812450/scale-h233-w233%5Ecompr-r85/9847/98477084/default_name.jpg 233w, https://assets.wfcdn.com/im/32539682/scale-h466-w466%5Ecompr-r85/9847/98477084/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/58336066/scale-h249-w249%5Ecompr-r85/9847/98477084/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p> Baby & Kids</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Appliances"
                    srcset="https://assets.wfcdn.com/im/58257284/scale-h195-w195%5Ecompr-r85/9847/98477082/default_name.jpg 195w, https://assets.wfcdn.com/im/54352228/scale-h390-w390%5Ecompr-r85/9847/98477082/default_name.jpg 390w, https://assets.wfcdn.com/im/27313828/scale-h176-w176%5Ecompr-r85/9847/98477082/default_name.jpg 176w, https://assets.wfcdn.com/im/02501980/scale-h352-w352%5Ecompr-r85/9847/98477082/default_name.jpg 352w, https://assets.wfcdn.com/im/52453020/scale-h233-w233%5Ecompr-r85/9847/98477082/default_name.jpg 233w, https://assets.wfcdn.com/im/39725788/scale-h466-w466%5Ecompr-r85/9847/98477082/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/81037892/scale-h249-w249%5Ecompr-r85/9847/98477082/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p>Appliances</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Sale"
                    srcset="https://assets.wfcdn.com/im/73450240/scale-h195-w195%5Ecompr-r85/3034/303480168/default_name.jpg 195w, https://assets.wfcdn.com/im/82589728/scale-h390-w390%5Ecompr-r85/3034/303480168/default_name.jpg 390w, https://assets.wfcdn.com/im/69562848/scale-h176-w176%5Ecompr-r85/3034/303480168/default_name.jpg 176w, https://assets.wfcdn.com/im/69782240/scale-h352-w352%5Ecompr-r85/3034/303480168/default_name.jpg 352w, https://assets.wfcdn.com/im/60907552/scale-h233-w233%5Ecompr-r85/3034/303480168/default_name.jpg 233w, https://assets.wfcdn.com/im/44548256/scale-h466-w466%5Ecompr-r85/3034/303480168/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/75056576/scale-h249-w249%5Ecompr-r85/3034/303480168/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p>Black Friday Sneak Peek</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Outdoor"
                    srcset="https://assets.wfcdn.com/im/58862486/scale-h195-w195%5Ecompr-r85/9847/98477118/default_name.jpg 195w, https://assets.wfcdn.com/im/54957430/scale-h390-w390%5Ecompr-r85/9847/98477118/default_name.jpg 390w, https://assets.wfcdn.com/im/67048266/scale-h176-w176%5Ecompr-r85/9847/98477118/default_name.jpg 176w, https://assets.wfcdn.com/im/98103222/scale-h352-w352%5Ecompr-r85/9847/98477118/default_name.jpg 352w, https://assets.wfcdn.com/im/51847818/scale-h233-w233%5Ecompr-r85/9847/98477118/default_name.jpg 233w, https://assets.wfcdn.com/im/39120586/scale-h466-w466%5Ecompr-r85/9847/98477118/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/81643094/scale-h249-w249%5Ecompr-r85/9847/98477118/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p>Outdoor</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Bedding &amp; Bath"
                    srcset="https://assets.wfcdn.com/im/65193248/scale-h195-w195%5Ecompr-r85/1323/132350539/default_name.jpg 195w, https://assets.wfcdn.com/im/25667264/scale-h390-w390%5Ecompr-r85/1323/132350539/default_name.jpg 390w, https://assets.wfcdn.com/im/38694144/scale-h176-w176%5Ecompr-r85/1323/132350539/default_name.jpg 176w, https://assets.wfcdn.com/im/61525248/scale-h352-w352%5Ecompr-r85/1323/132350539/default_name.jpg 352w, https://assets.wfcdn.com/im/52650560/scale-h233-w233%5Ecompr-r85/1323/132350539/default_name.jpg 233w, https://assets.wfcdn.com/im/42162048/scale-h466-w466%5Ecompr-r85/1323/132350539/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/11653728/scale-h249-w249%5Ecompr-r85/1323/132350539/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p>Bedding & Bath </p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Kitchen"
                    srcset="https://assets.wfcdn.com/im/45691817/scale-h195-w195%5Ecompr-r85/2692/269209725/default_name.jpg 195w, https://assets.wfcdn.com/im/54831305/scale-h390-w390%5Ecompr-r85/2692/269209725/default_name.jpg 390w, https://assets.wfcdn.com/im/41804425/scale-h176-w176%5Ecompr-r85/2692/269209725/default_name.jpg 176w, https://assets.wfcdn.com/im/42023817/scale-h352-w352%5Ecompr-r85/2692/269209725/default_name.jpg 352w, https://assets.wfcdn.com/im/33149129/scale-h233-w233%5Ecompr-r85/2692/269209725/default_name.jpg 233w, https://assets.wfcdn.com/im/72306679/scale-h466-w466%5Ecompr-r85/2692/269209725/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/02814999/scale-h249-w249%5Ecompr-r85/2692/269209725/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p>Kitchen</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Rugs"
                    srcset="https://assets.wfcdn.com/im/81407870/scale-h195-w195%5Ecompr-r85/9847/98477121/default_name.jpg 195w, https://assets.wfcdn.com/im/77502814/scale-h390-w390%5Ecompr-r85/9847/98477121/default_name.jpg 390w, https://assets.wfcdn.com/im/50464414/scale-h176-w176%5Ecompr-r85/9847/98477121/default_name.jpg 176w, https://assets.wfcdn.com/im/79351394/scale-h352-w352%5Ecompr-r85/9847/98477121/default_name.jpg 352w, https://assets.wfcdn.com/im/29302434/scale-h233-w233%5Ecompr-r85/9847/98477121/default_name.jpg 233w, https://assets.wfcdn.com/im/78392094/scale-h466-w466%5Ecompr-r85/9847/98477121/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/04188478/scale-h249-w249%5Ecompr-r85/9847/98477121/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p>Rugs</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Decor &amp; Pillows"
                    srcset="https://assets.wfcdn.com/im/62260834/scale-h195-w195%5Ecompr-r85/9847/98477095/default_name.jpg 195w, https://assets.wfcdn.com/im/36611518/scale-h390-w390%5Ecompr-r85/9847/98477095/default_name.jpg 390w, https://assets.wfcdn.com/im/63649918/scale-h176-w176%5Ecompr-r85/9847/98477095/default_name.jpg 176w, https://assets.wfcdn.com/im/01501570/scale-h352-w352%5Ecompr-r85/9847/98477095/default_name.jpg 352w, https://assets.wfcdn.com/im/48449470/scale-h233-w233%5Ecompr-r85/9847/98477095/default_name.jpg 233w, https://assets.wfcdn.com/im/35722238/scale-h466-w466%5Ecompr-r85/9847/98477095/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/09925854/scale-h249-w249%5Ecompr-r85/9847/98477095/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p> Decor & Pillows</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Home Improvement"
                    srcset="https://assets.wfcdn.com/im/79193803/scale-h195-w195%5Ecompr-r85/9847/98477108/default_name.jpg 195w, https://assets.wfcdn.com/im/83098859/scale-h390-w390%5Ecompr-r85/9847/98477108/default_name.jpg 390w, https://assets.wfcdn.com/im/89862741/scale-h176-w176%5Ecompr-r85/9847/98477108/default_name.jpg 176w, https://assets.wfcdn.com/im/39953067/scale-h352-w352%5Ecompr-r85/9847/98477108/default_name.jpg 352w, https://assets.wfcdn.com/im/05063189/scale-h233-w233%5Ecompr-r85/9847/98477108/default_name.jpg 233w, https://assets.wfcdn.com/im/17790421/scale-h466-w466%5Ecompr-r85/9847/98477108/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/56413195/scale-h249-w249%5Ecompr-r85/9847/98477108/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p> Home Improvement</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Lighting"
                    srcset="https://assets.wfcdn.com/im/92915225/scale-h195-w195%5Ecompr-r85/9847/98477115/default_name.jpg 195w, https://assets.wfcdn.com/im/05957127/scale-h390-w390%5Ecompr-r85/9847/98477115/default_name.jpg 390w, https://assets.wfcdn.com/im/32995527/scale-h176-w176%5Ecompr-r85/9847/98477115/default_name.jpg 176w, https://assets.wfcdn.com/im/32155961/scale-h352-w352%5Ecompr-r85/9847/98477115/default_name.jpg 352w, https://assets.wfcdn.com/im/17795079/scale-h233-w233%5Ecompr-r85/9847/98477115/default_name.jpg 233w, https://assets.wfcdn.com/im/05067847/scale-h466-w466%5Ecompr-r85/9847/98477115/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/79271463/scale-h249-w249%5Ecompr-r85/9847/98477115/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p>Lighting</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Rugs"
                    srcset="https://assets.wfcdn.com/im/81407870/scale-h195-w195%5Ecompr-r85/9847/98477121/default_name.jpg 195w, https://assets.wfcdn.com/im/77502814/scale-h390-w390%5Ecompr-r85/9847/98477121/default_name.jpg 390w, https://assets.wfcdn.com/im/50464414/scale-h176-w176%5Ecompr-r85/9847/98477121/default_name.jpg 176w, https://assets.wfcdn.com/im/79351394/scale-h352-w352%5Ecompr-r85/9847/98477121/default_name.jpg 352w, https://assets.wfcdn.com/im/29302434/scale-h233-w233%5Ecompr-r85/9847/98477121/default_name.jpg 233w, https://assets.wfcdn.com/im/78392094/scale-h466-w466%5Ecompr-r85/9847/98477121/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/04188478/scale-h249-w249%5Ecompr-r85/9847/98477121/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p>Rugs</p>
                </div>{" "}
                <div className="main2_shop_body">
                  <img
                    alt="Furniture"
                    srcset="https://assets.wfcdn.com/im/00262588/scale-h195-w195%5Ecompr-r85/9847/98477101/default_name.jpg 195w, https://assets.wfcdn.com/im/96357532/scale-h390-w390%5Ecompr-r85/9847/98477101/default_name.jpg 390w, https://assets.wfcdn.com/im/69319132/scale-h176-w176%5Ecompr-r85/9847/98477101/default_name.jpg 176w, https://assets.wfcdn.com/im/60496676/scale-h352-w352%5Ecompr-r85/9847/98477101/default_name.jpg 352w, https://assets.wfcdn.com/im/10447716/scale-h233-w233%5Ecompr-r85/9847/98477101/default_name.jpg 233w, https://assets.wfcdn.com/im/97720484/scale-h466-w466%5Ecompr-r85/9847/98477101/default_name.jpg 466w"
                    src="https://assets.wfcdn.com/im/23043196/scale-h249-w249%5Ecompr-r85/9847/98477101/default_name.jpg"
                    sizes="(min-width:1780px) 249px, (min-width:960px) 14vw, (min-width:640px) 20vw, 33vw"
                    data-hb-id="FluidImage"
                    class="b32ygh0"
                  />
                  <p>Furniture</p>
                </div>
              </div>
            </div>

            <div className="main2_bot_box">
              <div className="main2_bot_box_grid">
                <div className="main2_bot_body">
                  <img
                    alt="Selection"
                    srcset="https://assets.wfcdn.com/im/06871307/resize-h64-w64%5Ecompr-r85/1711/171106361/default_name.jpg 1x, https://assets.wfcdn.com/im/17031803/resize-h128-w128%5Ecompr-r85/1711/171106361/default_name.jpg 2x"
                    src="https://assets.wfcdn.com/im/06871307/resize-h64-w64%5Ecompr-r85/1711/171106361/default_name.jpg"
                    data-hb-id="FixedImage"
                    class=""
                  ></img>
                  <h2>Unbeatable Selection</h2>
                </div>
                <div className="main2_bot_body">
                  <img
                    alt=" Customer Service"
                    srcset="https://assets.wfcdn.com/im/40924046/resize-h64-w64%5Ecompr-r85/1711/171106364/default_name.jpg 1x, https://assets.wfcdn.com/im/51084542/resize-h128-w128%5Ecompr-r85/1711/171106364/default_name.jpg 2x"
                    src="https://assets.wfcdn.com/im/40924046/resize-h64-w64%5Ecompr-r85/1711/171106364/default_name.jpg"
                    data-hb-id="FixedImage"
                    class=""
                  />
                  <h2>Expert Customer Service</h2>
                </div>
                <div className="main2_bot_body">
                  <img
                    alt=" Shipping"
                    srcset="https://assets.wfcdn.com/im/97288685/resize-h64-w64%5Ecompr-r85/1711/171106369/default_name.jpg 1x, https://assets.wfcdn.com/im/87128189/resize-h128-w128%5Ecompr-r85/1711/171106369/default_name.jpg 2x"
                    src="https://assets.wfcdn.com/im/97288685/resize-h64-w64%5Ecompr-r85/1711/171106369/default_name.jpg"
                    data-hb-id="FixedImage"
                    class=""
                  />
                  <h2>Fast & Free Shipping Over $35*</h2>
                </div>
                <div className="main2_bot_body">
                  <img
                    alt="Value"
                    srcset="https://assets.wfcdn.com/im/31184982/resize-h64-w64%5Ecompr-r85/1711/171106371/default_name.jpg 1x, https://assets.wfcdn.com/im/21024486/resize-h128-w128%5Ecompr-r85/1711/171106371/default_name.jpg 2x"
                    src="https://assets.wfcdn.com/im/31184982/resize-h64-w64%5Ecompr-r85/1711/171106371/default_name.jpg"
                    data-hb-id="FixedImage"
                    class=""
                  />
                  <h2>Amazing Value Every Day</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
