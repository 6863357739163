import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contactus.css";

function Contact() {
  return (
    <div className="main-container">
      <Header />
      <div className="about_box">
        <div className="about_box_inner">
          <div className="about1_box about2_box">
            <img
              alt=""
              src="	https://ak1.ostkcdn.com/img/mxc/06202024-contact-hero-medium-desktop-1400x250-2.svg?imwidth=1920"
            />
          </div>
          <p>
            Have questions? We're happy to answer any questions you may have.
            Keep in touch by contacting any of the following:
          </p>
          <h3>
            <strong>headquarters</strong>
          </h3>
          <h6>799 W. Coliseum Way</h6>
          <p>Midvale, UT 84047</p>
          <p>
            <strong>Phone:</strong>{" "}
            <a href="tel:8019473100" target="_self">
              <strong>(415) 425-9345</strong>
            </a>
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>customer care</strong>
          </h3>
          <p>
            <strong>
              Our team is ready to help! For the most convenient way to start
              your help request, visit our Help Center and My Account.
            </strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <h6>
            <strong>Click </strong>
            <a href="/">
              <strong>HERE</strong>
            </a>
            <strong> to Chat</strong>
          </h6>
          <h6>
            <strong>Text: </strong>
            <a href="sms:8147476702" target="_self">
              <strong>1-814-747-6702</strong>
            </a>
          </h6>
          <p>
            <strong>Phone: </strong>
            <a href="tel:8008432446" target="_self">
              <strong>(415) 425-9345</strong>
            </a>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            <strong>My Account: </strong>
            <a href="/" target="_self">
              <strong>https://www.bedbathandbeyond.com/myaccount#/</strong>
            </a>
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>investor relations</strong>
          </h3>
          <p>
            <strong>Email: </strong>
            <a href="mailto:fitness@diarymu.click" target="_self">
              <strong>fitness@diarymu.click</strong>
            </a>
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>media relations</strong>
          </h3>
          <p>
            <strong>Email: </strong>
            <a href="mailto:fitness@diarymu.click" target="_self">
              <strong>fitness@diarymu.click</strong>
            </a>
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>product recalls</strong>
          </h3>
          <p>
            <strong>Online: </strong>
            <a href="/">
              <strong>
                https://help.bedbathandbeyond.com/help/s/article/Product-Safety-and-Recalls
              </strong>
            </a>
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>recruiting</strong>
          </h3>
          <p>
            <strong>Email: </strong>
            <a href="mailto:fitness@diarymu.click" target="_self">
              <strong>fitness@diarymu.click</strong>
            </a>
          </p>
          <p>
            <strong>Email: </strong>
            <a href="mailto:fitness@diarymu.click" target="_self">
              <strong>fitness@diarymu.click</strong>
            </a>
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>vendor offers</strong>
          </h3>
          <p>
            <strong>Online: </strong>
            <a href="/">
              <strong>https://www.bedbathandbeyond.com/partner</strong>
            </a>
          </p>
          <p>
            <strong></strong>
          </p>
          <p></p>
          <h3>
            <strong>
              <br bold="[object Object]" />
            </strong>
            <strong>transfer agent</strong>
          </h3>
          <h6>Computershare Investor Services</h6>
          <h6>P.O. Box 43023</h6>
          <p>Providence, RI 02940-3023</p>
          <p></p>
          <h6>
            <strong>Phone:</strong>
            <a href="tel:7815752879">
              <strong>(415) 425-9345</strong>
            </a>
          </h6>
          <h6>Fax:(415) 425-9345</h6>
          <p>
            <strong>Email:</strong>
            <a href="mailto:fitness@diarymu.click">
              <strong>fitness@diarymu.click</strong>
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
